import { Link } from 'react-router-dom';

import { resultProps } from './propTypes';

const Result = ({ index, id, url, title, category, description }) => (
  <div className="search-card">
    <div className="search-card__title-wrap">
      <p className="search-card__category smallest">{category}</p>
      <h2 className="search-card__title base-font no-chevron">
        <Link to={url} id={index === 0 ? 'search-results-first' : null}>
          {id}, <span>{title}</span>
        </Link>
      </h2>
    </div>
    <div className="search-card__description">
      <p>{description}</p>
    </div>
  </div>
);

Result.propTypes = resultProps;

Result.defaultProps = {
  index: null,
  title: null,
  description: null,
};

export default Result;
