import PropTypes from 'prop-types';

import { Accordion, P, renderLinks } from '../../Common';
import { some } from '../../Utils/tools';

const LearningOutcomes = ({
  organisationGeneric,
  organisation,
  learningOutcomes,
}) => {
  const showLearningOutcomes = some(
    organisationGeneric,
    organisation,
    learningOutcomes.length > 0,
  );
  return (
    showLearningOutcomes && (
      <Accordion label="Learning outcomes" controlId="learning-outcomes">
        {organisationGeneric && <p>{renderLinks(organisationGeneric)}</p>}
        {organisation && <P>{organisation}</P>}
        {learningOutcomes.length > 0 && (
          <>
            <h2 className="h3">Course learning outcomes</h2>
            <ol>
              {learningOutcomes.map((outcome) => (
                <li key={outcome}>
                  {outcome.charAt(0).toUpperCase() + outcome.slice(1)}
                </li>
              ))}
            </ol>
          </>
        )}
      </Accordion>
    )
  );
};

LearningOutcomes.propTypes = {
  organisationGeneric: PropTypes.string,
  organisation: PropTypes.string,
  learningOutcomes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

LearningOutcomes.defaultProps = {
  organisationGeneric: null,
  organisation: null,
};

export default LearningOutcomes;
