/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import { useEffect, useContext } from 'react';
import { Route } from 'react-router-dom';

import { PageNameContext } from '../BreadCrumbs/PageNameProvider';

const StaticNamedRoute = ({ routeName, ...props }) => {
  const { setPageName } = useContext(PageNameContext);
  useEffect(() => setPageName(routeName), [routeName, setPageName]);
  return <Route {...props} />;
};

StaticNamedRoute.propTypes = {
  routeName: PropTypes.string,
};

StaticNamedRoute.defaultProps = {
  routeName: null,
};

export default StaticNamedRoute;
