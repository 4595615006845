import { Page, Primary, Header } from '../Common';
import SupportMessage from './SupportMessage';

const ErrorMessage = () => (
  <Page>
    <Primary>
      <Header title="Something went wrong. " />
      <SupportMessage />
    </Primary>
  </Page>
);

export default ErrorMessage;
