import PropTypes from 'prop-types';

import { ListWithHeader } from '../Common';
import { summaryPropTypes } from './propTypes';

import './Summary.scss';

const Block = ({ header, items }) => (
  <div className="mobile_card__block">
    <ListWithHeader header={header} items={items} />
  </div>
);
Block.propTypes = {
  header: PropTypes.string.isRequired,
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  ]).isRequired,
};

const Summary = ({ summaryOptions }) => (
  <>
    {summaryOptions
      .filter(([, [value], required]) => required || (!required && value))
      .map(([key, value]) => (
        <Block key={key} header={key} items={value} />
      ))}
  </>
);

Summary.propTypes = summaryPropTypes;

export default Summary;
