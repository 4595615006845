import React from 'react';
import PropTypes from 'prop-types';
import GetStudyMode from './Utils/GetStudyMode';

export const StudyModeContext = React.createContext();

export const StudyModeProvider = ({ children }) => {
  const studyMode = GetStudyMode();

  return (
    <StudyModeContext.Provider value={studyMode}>
      {children}
    </StudyModeContext.Provider>
  );
};

StudyModeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
