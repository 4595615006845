import PropTypes from 'prop-types';

import { ReadMoreParagraph } from '../../Common';
import useIsMobile from '../../Utils/isMobile';

const Syllabus = ({ content }) => {
  const isMobile = useIsMobile();

  return (
    <>
      <h2>Syllabus</h2>
      {isMobile ? (
        <ReadMoreParagraph showMore>{content}</ReadMoreParagraph>
      ) : (
        <p>{content}</p>
      )}
    </>
  );
};

Syllabus.propTypes = {
  content: PropTypes.string,
};

Syllabus.defaultProps = {
  content: '',
};

export default Syllabus;
