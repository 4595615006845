import PropTypes from 'prop-types';

import { StickyUiBottom } from '../Common';

const StickyUiLinks = ({ seoFriendlyUrl, type, studyArea }) => (
  <StickyUiBottom
    links={[
      {
        link: `https://study.curtin.edu.au/offering/${seoFriendlyUrl}/#how-to-apply/`,
        label: `Read more about this ${type}`,
        ico: 'book',
      },
      {
        link: `https://study.curtin.edu.au/study-areas/${studyArea}`,
        label: 'Your study area',
        ico: 'options',
      },
    ]}
  />
);

StickyUiLinks.propTypes = {
  seoFriendlyUrl: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  studyArea: PropTypes.string,
};

StickyUiLinks.defaultProps = {
  studyArea: null,
};

export default StickyUiLinks;
