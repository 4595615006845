import PropTypes from 'prop-types';

const NoResults = ({ searchValue }) => (
  <div className="search__no-results">
    <h2>
      Sorry, we couldn&apos;t find any results for &ldquo;
      <strong>{searchValue}</strong>&rdquo;
    </h2>
    <p>Search tips:</p>
    <ul>
      <li>Check your spelling and try again.</li>
      <li>Try clearing your filters or using different ones.</li>
      <li>
        Use simple search terms as our search function works best with shorter
        descriptions.
      </li>
    </ul>
  </div>
);

NoResults.propTypes = {
  searchValue: PropTypes.string.isRequired,
};

export default NoResults;
