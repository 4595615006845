import { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const MobileApplyLinkContext = createContext({
  mobileApplyLink: null,
  setMobileApplyLink: () => null,
});

export const MobileApplyLinkProvider = ({ children }) => {
  const [mobileApplyLink, setMobileApplyLink] = useState(null);
  return (
    <MobileApplyLinkContext.Provider
      value={{ mobileApplyLink, setMobileApplyLink }}
    >
      {children}
    </MobileApplyLinkContext.Provider>
  );
};

MobileApplyLinkProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
