import PropTypes from 'prop-types';

const Secondary = ({ children }) => (
  <aside className="no-masthead active">{children}</aside>
);

Secondary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Secondary;
