/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { rulePropType } from './propTypes';
import Boolean from './Boolean';
import { resolveTypeCode } from './constants';
import { renderHeadingComponents } from './utils';
import plural from '../../../Utils/plural';
import './Rule.scss';

const isBooleanOperator = (operator) => ['AND', 'OR'].includes(operator);

// prettier-ignore
const blockHasNesting = (operands) =>
  operands.filter((operand) =>
    ['AND', 'OR', 'CV', 'ADM'].includes(operand.operator)
  ).length > 0;

const Magenta = ({ children }) => (
  <span className="magenta_text">{children}</span>
);

Magenta.propTypes = {
  children: PropTypes.string.isRequired,
};

const renderDescriptor = (operator, countText, separator, ruleValue) => {
  if (operator === 'CV') {
    return (
      <>
        {countText !== '' && (
          <>
            {' '}
            <Magenta>{countText}</Magenta>
          </>
        )}{' '}
        credit {plural(ruleValue, 'point')}
      </>
    );
  }
  return (
    <>
      {countText && (
        <>
          {' '}
          <Magenta>{countText}</Magenta>
        </>
      )}
      {separator ? ` ${separator}` : ''}
    </>
  );
};

// prettier-ignore
const resolveString = (typeCode, operator, operands) => {
  const {
    ruleValue,
    countText,
    separator,
    prefix,
    preSuffix,
  } = renderHeadingComponents(typeCode, operator, operands);
  const descriptor = renderDescriptor(
    operator,
    countText,
    separator,
    ruleValue,
  );
  return (
    <>
      {prefix}
      {descriptor}
      {preSuffix}:
    </>
  );
};

const SubHeading = ({
  typeCode,
  operator,
  operands,
  hasNesting,
  groupId,
  groupLabel,
}) => (
  <div
    className={`rule_subheading${!groupLabel ? ' nested' : ''}`}
    id={groupId}
  >
    {groupLabel && (
      <p className="h4">{`${resolveTypeCode[typeCode]} ${groupLabel}`}</p>
    )}
    <span>{!hasNesting && resolveString(typeCode, operator, operands)}</span>
  </div>
);

SubHeading.propTypes = {
  typeCode: PropTypes.string.isRequired,
  operator: PropTypes.string.isRequired,
  operands: PropTypes.instanceOf(Array).isRequired,
  hasNesting: PropTypes.bool.isRequired,
  groupId: PropTypes.string.isRequired,
  groupLabel: PropTypes.string,
};

SubHeading.defaultProps = {
  groupLabel: null,
};

const resolveListClass = (depth, maxDepth) => {
  if (depth === 0) {
    if (maxDepth > 1) {
      return 'second-level';
    }
    return 'first-level';
  }
  return undefined;
};

const resolveRuleBlockClass = (depth) => {
  if (depth === 0) {
    return ' first';
  }
  if (depth === 1) {
    return ' white-background';
  }
  return '';
};

// prettier-ignore
const Rule = ({
  typeCode,
  rule,
  depth,
  maxDepth,
  groupIndex,
  concPreRequisites,
}) => {
  const groupLabel = `group ${groupIndex.join('.')}`;
  const groupId = `${typeCode}-${groupIndex.join('.')}`;

  const renderOperand = (operator, operand, index, hasNesting, numOperands) => {
    const childGroupIndex = [...groupIndex, index + 1];
    const childGroupLabel = `group ${childGroupIndex.join('.')}`;
    const showBooleanIcon =
      hasNesting && index < numOperands - 1 && isBooleanOperator(operator);
    const oneOrAllText = operator === 'AND' ? 'all' : 'at least one';
    const groupTextA = groupIndex.length > 0 ? ` as part of ${groupLabel}` : '';
    const groupTextB = groupIndex.length > 0 ? ` within ${groupLabel} ` : ' ';
    return (
      <li
        key={index}
        aria-label={
          operand.operator
            ? `${childGroupLabel} to be completed${groupTextA}. ${oneOrAllText} of the groups${groupTextB}must be completed.`
            : `Option ${index + 1} to be completed as part of ${groupLabel}`
        }
      >
        <Rule
          typeCode={typeCode}
          rule={operand}
          depth={depth + 1}
          maxDepth={maxDepth}
          groupIndex={childGroupIndex}
          concPreRequisites={concPreRequisites}
        />
        {showBooleanIcon && <Boolean operator={operator} />}
      </li>
    );
  };

  if (rule.operator) {
    const { operator, operands } = rule;
    const hasNesting = blockHasNesting(operands);
    const numOperands = operands.length;
    const isGroupEntry = depth === 1 || depth === 2;
    return (
      <div className={`handbook__rule_block${resolveRuleBlockClass(depth)}`}>
        {isGroupEntry && (
          <SubHeading
            typeCode={typeCode}
            operator={operator}
            operands={operands}
            hasNesting={hasNesting}
            groupLabel={depth === 1 ? groupLabel : null}
            groupId={groupId}
          />
        )}
        <ul
          aria-labelledby={isGroupEntry ? groupId : null}
          className={resolveListClass(depth, maxDepth)}
        >
          {operands.map((operand, index) =>
            renderOperand(operator, operand, index, hasNesting, numOperands),
          )}
        </ul>
      </div>
    );
  }

  const { offering, title, link } = rule;

  const isConcPreRequisite = (offering) =>
    concPreRequisites.includes(offering)
      ? <span className="magenta_text"> (Concurrent)</span>
      : null;

  return (
    <div className="rule_offering">
      {link ? (
        <>
          <span>
            <Link to={link}>{offering}</Link>
           {isConcPreRequisite(offering)}
          </span>
        </>
      ) : (
        <>
          <span>
            {offering}
            {isConcPreRequisite(offering)}
          </span>
        </>
      )}
      {link ? (
        <span>
          <Link to={link}>{title}</Link>
        </span>
      ) : (
        <span>{title}</span>
      )}
    </div>
  );
};

Rule.propTypes = {
  typeCode: PropTypes.string.isRequired,
  rule: rulePropType.isRequired,
  depth: PropTypes.number,
  maxDepth: PropTypes.number.isRequired,
  groupIndex: PropTypes.arrayOf(PropTypes.number),
  concPreRequisites: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Rule.defaultProps = {
  depth: 0,
  groupIndex: [],
};

export default Rule;
