import PropTypes from 'prop-types';
import { useState } from 'react';

import SearchInput from './SearchInput';
import { Search } from '../Icons';

import './RadioGroup.scss';

const RadioButton = ({ label, value, checked, groupLabel, onChange }) => (
  <div className="filter__option-wrap">
    <label
      className={`filter__option_label${checked ? ' selected' : ''}`}
      htmlFor={value}
    >
      {label}
      <input
        type="radio"
        id={value}
        className="filter__option"
        name={groupLabel}
        checked={checked}
        onClick={() => onChange(value)}
        onChange={() => null}
      />
    </label>
  </div>
);

RadioButton.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  groupLabel: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

RadioButton.defaultProps = {
  value: null,
};

const RadioGroup = ({
  selected,
  options,
  onChange,
  groupLabel,
  selectAll,
  inputLabel,
}) => {
  const [search, setSearch] = useState('');
  const lowerCaseSearch = search.toLowerCase();
  const hasScrollBar = options.length > 10;

  const onSelectedChange = (value) => {
    if (value !== selected) {
      onChange(value);
    }
  };

  return (
    <div className="filter__field styled handbook__radio_group">
      {hasScrollBar && (
        <SearchInput
          value={search}
          onChange={setSearch}
          disabled={false}
          inputLabel={inputLabel}
        >
          <Search colour="gray" />
        </SearchInput>
      )}
      <div className={hasScrollBar ? 'default_value' : null}>
        <RadioButton
          label={selectAll.label}
          value={selectAll.value}
          checked={selected === selectAll.value}
          groupLabel={groupLabel}
          onChange={onSelectedChange}
        />
      </div>
      <div className="radio_group__scrollable handbook__with_scrollbar">
        {options
          .filter(({ label }) => label.toLowerCase().includes(lowerCaseSearch))
          .map(({ label, value }) => (
            <RadioButton
              key={value}
              label={label}
              value={value}
              checked={value === selected}
              groupLabel={groupLabel}
              onChange={onSelectedChange}
            />
          ))}
      </div>
    </div>
  );
};

RadioGroup.propTypes = {
  selected: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string,
    }),
  ).isRequired,
  groupLabel: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selectAll: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
  }).isRequired,
  inputLabel: PropTypes.string.isRequired,
};

RadioGroup.defaultProps = {
  selected: null,
};

export default RadioGroup;
