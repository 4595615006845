import PropTypes from 'prop-types';

import { Card, CardContent } from '../Common';

import './SideBarLinks.scss';

const LinkTag = ({ link, label }) => (
  <a key={link} href={link} aria-label={label} target="_blank" rel="noreferrer">
    {label}
  </a>
);

LinkTag.propTypes = {
  link: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

const getLinks = () => (
  <>
    <ul className="link-list">
      <li>
        <LinkTag
          link="https://study.curtin.edu.au/undergraduate/uni-words/"
          label="Common uni words"
        />
      </li>
      <li>
        <LinkTag
          link="https://archive.handbook.curtin.edu.au/"
          label="Handbook archive"
        />
      </li>
    </ul>
  </>
);

const SideBarLinks = ({ isMobile }) => (
  <>
    {isMobile ? (
      <>
        <div className="page-components contained" id="useful-links-component">
          <section className="support" id="useful-links">
            <h3>Useful links</h3>
            {getLinks()}
          </section>
        </div>
      </>
    ) : (
      <Card>
        <CardContent>
          <h3>Useful links</h3>
          <div className="card__links">{getLinks()}</div>
        </CardContent>
      </Card>
    )}
  </>
);

SideBarLinks.propTypes = {
  isMobile: PropTypes.bool,
};

SideBarLinks.defaultProps = {
  isMobile: false,
};

export default SideBarLinks;
