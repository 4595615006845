import PropTypes from 'prop-types';

import './Table.scss';

const childrenPropType = {
  children: PropTypes.node.isRequired,
};

const Header = ({ children }) => <th scope="col">{children}</th>;

Header.propTypes = childrenPropType;

const Headers = ({ children }) => (
  <thead>
    <tr role="row">{children}</tr>
  </thead>
);

Headers.propTypes = childrenPropType;

const RowValue = ({ children }) => (
  <td>
    <span>{children}</span>
  </td>
);

RowValue.propTypes = childrenPropType;

const Row = ({ children }) => <tr role="row">{children}</tr>;

Row.propTypes = childrenPropType;

const Rows = ({ children }) => <tbody>{children}</tbody>;

Rows.propTypes = childrenPropType;

const Table = ({ children, className }) => (
  <table className={className} role="grid">
    {children}
  </table>
);

Table.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Table.defaultProps = {
  className: undefined,
};

export { Header, Headers, RowValue, Row, Rows, Table };
