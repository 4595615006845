import PropTypes from 'prop-types';

const rulePropType = PropTypes.oneOfType([
  PropTypes.array,
  PropTypes.shape({
    offering: PropTypes.string,
    title: PropTypes.string,
    ruleCode: PropTypes.string,
  }),
]);

export { rulePropType };
