import PropTypes from 'prop-types';
import get from 'lodash.get';

import { Modal } from '../../../Common';

const CONTENT_MAP = {
  E: {
    buttonValue: 'What is an equivalent?',
    headerValue: 'What is an equivalent?',
    content:
      'A unit that is equivalent to this unit but is not designated as an antirequisite.',
  },
  A: {
    buttonValue: 'What are antirequisites?',
    headerValue: 'What are antirequisites?',
    content:
      'Antirequisites are units that are similar in content. Students can’t enrol into two similar units.',
  },
  P: {
    buttonValue: 'What are prerequisites?',
    headerValue: 'What are prerequisites?',
    content:
      'Prerequisites are courses or units that must be completed or admitted into before enrolling in this unit.',
  },
  C: {
    buttonValue: 'What are corequisites?',
    headerValue: 'What are corequisites?',
    content:
      'Corequisites are units that must be studied together with this unit. Withdrawing from one unit will automatically result in withdrawing from the others.',
  },
};

const PopUp = ({ typeCode }) => {
  const { buttonValue, headerValue, content } = get(CONTENT_MAP, [typeCode], {
    buttonValue: null,
    headerValue: null,
    content: null,
  });
  return (
    <Modal
      buttonValue={buttonValue}
      customButtonStyles="pink"
      headerValue={headerValue}
    >
      <p style={{ margin: 0 }}>{content}</p>
    </Modal>
  );
};

PopUp.propTypes = {
  typeCode: PropTypes.string.isRequired,
};

export default PopUp;
