import PropTypes from 'prop-types';

import './ListWithHeader.scss';

const ListWithHeader = ({ header, items, content }) => (
  <>
    <h3 className="handbook__list_with_header__header">{header}</h3>
    <ul className="handbook__list_with_header__list">
      {items.map((item) => (
        <li key={item}>{content(item)}</li>
      ))}
    </ul>
  </>
);

ListWithHeader.propTypes = {
  header: PropTypes.string.isRequired,
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
  ]).isRequired,
  content: PropTypes.func,
};

ListWithHeader.defaultProps = {
  content: (item) => item,
};

export default ListWithHeader;
