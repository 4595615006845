import './Help.scss';

const Help = () => (
  <>
    <div className="page-components contained" id="help-component">
      <a
        href="#help"
        className="support-skip"
        aria-label="Help"
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          border: '1px solid #f0f0f0',
        }}
      >
        Help
        <span className="ico ico-faq ico--white">
          <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path d="M26 2H14a4 4 0 0 0-4 4v4H6a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4h10.67L22 30V18h4a4 4 0 0 0 4-4V6a4 4 0 0 0-4-4zm-6 24l-2.67-2H6a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h4v10l5.33-4H20zm8-12a2 2 0 0 1-2 2H14.67L12 18V6a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2z" />
            <path d="M15 7h10v2H15zM15 11h7v2h-7z" />
          </svg>
        </span>
        <span className="ico ico-chevron ico--white ico--small">
          <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path d="M11.71 26.71l-1.42-1.42 9.3-9.29-9.3-9.29 1.42-1.42L22.41 16l-10.7 10.71z" />
          </svg>
        </span>
      </a>
      <section className="support" id="help" data-segment="dom">
        <div className="grid">
          <div className="support__wrap">
            <div className="support__content-wrap">
              <h2 className="support__title h2">Help is at hand</h2>
            </div>
            <ul className="support__faqs">
              <li>
                <a
                  href="https://future.connect.curtin.edu.au/?usa=support"
                  className="support__faq small small strong"
                  target="_blank"
                  rel="noreferrer"
                >
                  Browse all FAQs
                </a>
              </li>

              <li>
                <a
                  href="https://students.curtin.edu.au/essentials/"
                  className="support__faq small"
                  aria-label="Student essentials section for fees, student id, forms, etc."
                  target="_blank"
                  rel="noreferrer"
                >
                  Student Essentials
                </a>
              </li>

              <li>
                <a
                  href="https://students.curtin.edu.au/essentials/fees/"
                  className="support__faq small"
                  aria-label="Understanding Student Fees and Financial Assistance."
                  target="_blank"
                  rel="noreferrer"
                >
                  Understanding Student Fees and Financial Assistance
                </a>
              </li>

              <li>
                <a
                  href="https://students.curtin.edu.au/"
                  className="support__faq small"
                  aria-label="Search Current Students website."
                  target="_blank"
                  rel="noreferrer"
                >
                  Search Current Students website
                </a>
              </li>

              <li>
                <a
                  href="https://students.curtin.edu.au/essentials/scholarships/"
                  className="support__faq small"
                  aria-label="What Scholarships are available?"
                  target="_blank"
                  rel="noreferrer"
                >
                  What Scholarships are available?
                </a>
              </li>

              <li>
                <a
                  href="https://study.curtin.edu.au/"
                  className="support__faq small small strong"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Study website"
                >
                  Study website
                </a>
              </li>
            </ul>
            <hr />

            <ul
              className="support__contacts"
              style={{ justifyContent: 'space-evenly' }}
            >
              <li className="support__contact">
                <span className="ico ico-phone ico--small ico--gray-dark">
                  <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 32 32">
                    <path d="M21 0H11a5 5 0 00-5 5v22a5 5 0 005 5h10a5 5 0 005-5V5a5 5 0 00-5-5zm3 27a3 3 0 01-3 3H11a3 3 0 01-3-3v-4h16zm0-6H8V5a3 3 0 013-3h10a3 3 0 013 3z" />
                    <path d="M14.5 28h3a1.5 1.5 0 000-3h-3a1.5 1.5 0 000 3z" />
                  </svg>
                </span>
                <a href="tel:1300222888" className="pink-link small">
                  1300 222 888
                </a>
              </li>
            </ul>

            <dl className="support__texts mt-g small">
              <dt>
                <span className="ico ico-time ico--small ico--gray-dark">
                  <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 32 32">
                    <path d="M16 2a14 14 0 1 0 14 14A14 14 0 0 0 16 2zm0 26a12 12 0 1 1 12-12 12 12 0 0 1-12 12z" />
                    <path d="M17 7h-2v10h8v-2h-6V7z" />
                  </svg>
                </span>
                Opening hours:
              </dt>
              <dd>
                Mon to Fri: <strong>8.30am – 4.30pm</strong>, except Tues:{' '}
                <strong>9.30am – 4.30pm</strong> (AWST). Closed public holidays.
              </dd>
            </dl>

            <div className="support__buttons">
              <a
                href="https://students.connect.curtin.edu.au/"
                className="button button--deep-blue"
                target="_blank"
                rel="noreferrer"
              >
                Curtin Connect
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  </>
);

export default Help;
