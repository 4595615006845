import { useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { GreaterThan, Home } from '../Icons';
import { PageNameContext } from './PageNameProvider';
import { MobileApplyLinkContext } from './MobileApplyLinkProvider';
import useIsMobile from '../Utils/isMobile';

import './BreadCrumbs.scss';

const LandingPageLink = () => (
  <li>
    <NavLink to="/">
      <Home label="Handbook Home" />
    </NavLink>
  </li>
);

const BreadCrumbs = () => {
  const { pageName } = useContext(PageNameContext);
  const { mobileApplyLink } = useContext(MobileApplyLinkContext);
  const location = useLocation();
  const isMobile = useIsMobile();
  const isUnitPage = location.pathname.toLowerCase().includes('unit');
  return (
    <>
      {!isMobile && pageName && (
        <section className="breadcrumbs handbook__breadcrumbs">
          <div className="contained">
            <div className="breadcrumbs__container">
              <nav aria-label="breadcrumbs">
                <ol>
                  <LandingPageLink />
                  <li>
                    <span className="handbook__breadcrumbs_separator">
                      <GreaterThan />
                    </span>
                    <NavLink to={location.pathname}>{pageName}</NavLink>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </section>
      )}
      {isMobile && pageName && (
        <div className="offering-nav-device handbook__stickyuitop">
          <div className="offering-nav-device__button handbook__landingpagelink">
            <NavLink to="/">HANDBOOK</NavLink>
          </div>
          {!isUnitPage && (
            <div className="handbook__applynow">
              <a
                href={mobileApplyLink}
                className="apply-button"
                target="_blank"
                rel="noreferrer"
              >
                Apply now
              </a>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default BreadCrumbs;
