import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { ButtonGroup } from '../../Common';
import PrimarySearch from './PrimarySearch';
import Suggestion from './Suggestion';
import DetailsPopUp from '../../DetailsPopUp';

import './SearchHeader.scss';

const RESULT_TYPES = {
  'All result types': 'all',
  Courses: 'course',
  Units: 'unit',
};

const SearchHeader = ({
  searchValue,
  onSearchValueChange,
  onSearchSubmit,
  studyTypes,
  onStudyTypesChange,
  disabled,
  suggestion,
}) => {
  const { search } = useLocation();
  const [showOptions, setShowOptions] = useState(false);
  const submitSearch = () => {
    onSearchSubmit();
    setShowOptions(false);
  };
  return (
    <section className="offering-search" id="offering-search">
      <h1>Curtin Handbook</h1>
      <p>
        This handbook contains detailed information on courses and their
        components at Curtin. Further course information can be found on the{' '}
        <a
          className="pink-link"
          target="_blank"
          rel="noreferrer"
          aria-label="Study website"
          href="https://study.curtin.edu.au/"
        >
          Study website
        </a>
      </p>
      <div
        className={`search-form${disabled ? ' handbook__disabled_input' : ''}`}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (disabled) {
              return;
            }
            submitSearch();
          }}
        >
          <PrimarySearch
            value={searchValue}
            onChange={onSearchValueChange}
            onSubmit={submitSearch}
            disabled={disabled}
            showOptions={showOptions}
            setShowOptions={setShowOptions}
          />
        </form>
        <Suggestion suggestion={suggestion} studyTypes={studyTypes} />
        <Link
          to={{ hash: '#search-results-first', search }}
          className="sr-jump"
        >
          Skip to results
        </Link>
        <ButtonGroup
          selected={studyTypes}
          options={RESULT_TYPES}
          onChange={onStudyTypesChange}
          disabled={disabled}
        />
      </div>
      <DetailsPopUp />
    </section>
  );
};

SearchHeader.propTypes = {
  searchValue: PropTypes.string.isRequired,
  onSearchValueChange: PropTypes.func.isRequired,
  onSearchSubmit: PropTypes.func.isRequired,
  studyTypes: PropTypes.string.isRequired,
  onStudyTypesChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  suggestion: PropTypes.string,
};

SearchHeader.defaultProps = {
  disabled: false,
  suggestion: null,
};

export default SearchHeader;
