/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const searchCourseDetails = /* GraphQL */ `
  query SearchCourseDetails($seoFriendlyUrlSuffix: String, $studyMode: String) {
    searchCourseDetails(
      seoFriendlyUrlSuffix: $seoFriendlyUrlSuffix
      studyMode: $studyMode
    ) {
      courseDetails {
        id
        seoFriendlyUrlSuffix
        longTitle
        udc
        version
        effectiveDate
        courseOverviewGeneric
        courseOverviewSpecific
        overviewNotes
        professionalRecognition
        accreditation
        careerOpportunities
        entryRequirements
        entryRequirementsSpecific
        entryOrCompletionNotes
        creditForRecognisedLearning
        creditForRecognisedLearningNotes
        intermediateAwards {
          intermediateStudyPackCode
          awardTitle
          __typename
        }
        awardPathwayGeneric
        awardList
        completionRequirements
        organisationGeneric
        organisation
        learningOutcomes
        durationAndAvailability
        additionalExpenses
        duration {
          hours
          months
          minutes
          days
          years
          __typename
        }
        locationsAndDelivery {
          campus
          campusEntries {
            year
            yearEntries {
              studyPeriod
              studyPeriodEntries
              __typename
            }
            __typename
          }
          __typename
        }
        awardCode
        cricosCode
        courseStructure {
          yearLevel
          yearLevelEntries {
            studyPeriod
            studyPeriodEntries {
              core {
                code
                credits
                offeringTitle
                offeringLink
                displayOrder
                __typename
              }
              electiveGroups {
                core {
                  structureComponent
                  maxCredits
                  structureComponentEntries {
                    code
                    credits
                    offeringTitle
                    offeringLink
                    displayOrder
                    __typename
                  }
                  __typename
                }
                options {
                  structureComponent
                  maxCredits
                  structureComponentEntries {
                    code
                    credits
                    offeringTitle
                    offeringLink
                    displayOrder
                    __typename
                  }
                  __typename
                }
                __typename
              }
              electiveV0Groups {
                totalCredits
                __typename
              }
              __typename
            }
            __typename
          }
          __typename
        }
        studyArea
        structureNotes
        category
        hiddenLocation
        __typename
      }
      courseVersions {
        version
        seoFriendlyUrlSuffix
        effectiveDate
        discontinuationDate
        stageCode
        __typename
      }
      __typename
    }
  }
`;
export const searchCourseOrUnit = /* GraphQL */ `
  query SearchCourseOrUnit(
    $studyMode: String!
    $studyTypes: [ResultTypes!]!
    $page: Int!
    $phrase: String
    $studyLevel: String
    $locationCode: String
    $fieldOfEducationCode: String
    $attendanceModeCode: String
  ) {
    searchCourseOrUnit(
      studyMode: $studyMode
      studyTypes: $studyTypes
      page: $page
      phrase: $phrase
      studyLevel: $studyLevel
      locationCode: $locationCode
      fieldOfEducationCode: $fieldOfEducationCode
      attendanceModeCode: $attendanceModeCode
    ) {
      totalMatches
      results {
        id
        url
        title
        category
        description
        __typename
      }
      suggestion
      filters {
        studyLevels {
          value
          label
          displayOrder
          __typename
        }
        fieldsOfEducation {
          value
          label
          __typename
        }
        locations {
          value
          label
          __typename
        }
        attendanceModes {
          value
          label
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const searchUnitDetails = /* GraphQL */ `
  query SearchUnitDetails($seoFriendlyUrlSuffix: String, $studyMode: String) {
    searchUnitDetails(
      seoFriendlyUrlSuffix: $seoFriendlyUrlSuffix
      studyMode: $studyMode
    ) {
      unitDetails {
        id
        longTitle
        udc
        version
        effectiveDate
        tuitionPatterns {
          tuitionPatternDescription
          contactHours
          frequencyValue
          frequencyContent
          __typename
        }
        furtherInformation
        locationsAndDelivery {
          campus
          campusEntries {
            year
            yearEntries {
              studyPeriod
              studyPeriodEntries
              __typename
            }
            __typename
          }
          __typename
        }
        syllabusContent
        contactHours
        resultType
        fieldOfEducation
        credits
        teachingOrg
        studyArea
        requisites {
          typeCode
          rule
          depth
          __typename
        }
        concPreRequisites
        __typename
      }
      unitVersions {
        version
        seoFriendlyUrlSuffix
        effectiveDate
        discontinuationDate
        stageCode
        __typename
      }
      __typename
    }
  }
`;
