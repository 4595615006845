import { Modal } from '../Common';

const DETAILS_LABEL = 'What is a course and what are its components?';

const DetailsPopUp = () => (
  <Modal buttonValue={DETAILS_LABEL} headerValue={DETAILS_LABEL}>
    <h3>Course</h3>
    <p>
      A course is a structured combination of approved units which when
      completed qualifies the student for an award from Curtin. A course may
      include a major(s), minor(s) and/or stream(s).
    </p>
    <h3>Unit</h3>
    <p>
      A unit is a discrete entity of study within a subject area and is the
      fundamental component for teaching, learning and assessment.
    </p>
    <h3>Major</h3>
    <p>
      A major is a series of units which pursues learning of a single discipline
      or area of study.
    </p>
    <h3>Minor / specialisation</h3>
    <p>
      A minor/specialisation is a smaller set of units designed to enhance
      graduate employability.
    </p>
    <h3>Stream</h3>
    <p>
      A stream is a specialised structure of units within a course and is used
      to define a specific pathway of study.
    </p>
  </Modal>
);

export default DetailsPopUp;
