import useIsMobile from '../Utils/isMobile';
import Summary from './Summary';
import { summaryPropTypes } from './propTypes';

import './MobileCard.scss';

const MobileCard = ({ summaryOptions }) => {
  const isMobile = useIsMobile();
  return (
    isMobile && (
      <section className="mobile_card">
        <Summary summaryOptions={summaryOptions} />
      </section>
    )
  );
};

MobileCard.propTypes = summaryPropTypes;

export default MobileCard;
