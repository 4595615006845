import { useContext } from 'react';
import { NotifierContext } from './NotifierProvider';

const Notifier = () => {
  const { message } = useContext(NotifierContext);
  return (
    <div className="sr-only" role="status" aria-live="polite" aria-atomic>
      {message && <span>{message}</span>}
    </div>
  );
};

export default Notifier;
