import PropTypes from 'prop-types';

const Card = ({ children, withShadow }) => (
  <div
    style={{ boxShadow: withShadow && '0 2px 8px 0 rgb(0 0 0 / 20%)' }}
    className="card"
  >
    <div className="inner">{children}</div>
  </div>
);

Card.propTypes = {
  children: PropTypes.node.isRequired,
  withShadow: PropTypes.bool,
};

Card.defaultProps = {
  withShadow: true,
};

export default Card;
