import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';

const CourseItem = ({
  children,
  title,
  type,
  ariaLabel,
  ariaControl,
  isOpen,
  isOverride,
  setOpen,
}) => (
  <div
    role="complementary"
    className="course_structure__parent"
    aria-label={ariaLabel}
    style={{ margin: 0 }}
  >
    <button
      className={`${type}${isOpen ? ' open' : ''}`}
      type="button"
      onClick={() => setOpen(!isOpen, false)}
      aria-expanded={isOpen ? 'true' : 'false'}
      aria-controls={ariaControl}
    >
      <span className="course_structure__content">{title}</span>
    </button>
    <AnimateHeight
      id={ariaControl}
      height={isOpen ? 'auto' : 0}
      duration={isOverride ? 0 : 500}
      className="course_structure__child"
    >
      {children}
    </AnimateHeight>
  </div>
);

CourseItem.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  ariaControl: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  isOverride: PropTypes.bool,
  setOpen: PropTypes.func,
};

CourseItem.defaultProps = {
  isOpen: false,
  isOverride: false,
  setOpen: () => {
    // No-op
  },
};

export default CourseItem;
