const halfScrollOverride = { scrollOverride: window.innerHeight * 0.3 };

const initSearchState = {
  totalMatches: 0,
  results: [],
  suggestion: null,
  filters: {
    studyLevels: null,
    fieldsOfEducation: null,
    locations: null,
    attendanceMode: null,
  },
  isLoading: true,
  searchTerm: '',
  isError: false,
  isRedirect: false,
};

// REDUCER ACTIONS
const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
const SET_RESULTS = 'SET_RESULTS';
const SET_ERROR = 'SET_ERROR';
const SET_LOADING = 'SET_LOADING';

export {
  halfScrollOverride,
  initSearchState,
  SET_SEARCH_TERM,
  SET_RESULTS,
  SET_ERROR,
  SET_LOADING,
};
