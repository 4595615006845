import PropTypes from 'prop-types';

import './CardGroup.scss';

const CardGroup = ({ children }) => (
  <div className="handbook__card_group">{children}</div>
);

CardGroup.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CardGroup;
