import useIsMobile from '../Utils/isMobile';
import { Secondary, Card, CardContent, CardGroup } from '../Common';
import SideBarLinks from '../SideBarLinks';
import Summary from './Summary';
import StickyUiLinks from './StickyUiLinks';
import UnitDetails from './UnitDetails';
import { sideBarPropTypes } from './propTypes';
import { Book, Download, Options } from '../Icons';

import './SideBar.scss';

const printScreen = (event) => {
  window.print();
  event.preventDefault();
};

const SideBar = ({ sideBarOptions, summaryOptions, type }) => {
  const { studyArea, seoFriendlyUrl } = sideBarOptions;
  const isMobile = useIsMobile();

  return (
    <Secondary>
      <CardGroup>
        {!isMobile && (
          <Card>
            <CardContent>
              <Summary summaryOptions={summaryOptions} />
              <h2 className="h3">More details</h2>
              <button
                type="button"
                className="sidebar-button"
                onClick={printScreen}
              >
                <Download /> <span>Download PDF</span>
              </button>
              {type === 'course' && (
                <a
                  className="sidebar-button link"
                  href={`https://study.curtin.edu.au/offering/${seoFriendlyUrl}/#how-to-apply/`}
                  aria-label={`Read more about this ${type}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Book colour="white" />
                  <span>Read more about this {type}</span>
                </a>
              )}
              <a
                className="sidebar-button link"
                href={`https://study.curtin.edu.au/study-areas/${studyArea}`}
                aria-label="Your study area"
                target="_blank"
                rel="noreferrer"
              >
                <Options colour="white" /> <span>Your study area</span>
              </a>
              {type === 'course' && (
                <a
                  className="sidebar-button link apply"
                  href={`https://www.curtin.edu.au/study/offering/${seoFriendlyUrl}/?apply`}
                  aria-label="Apply now"
                  target="_blank"
                  rel="noreferrer"
                >
                  APPLY NOW
                </a>
              )}
            </CardContent>
          </Card>
        )}

        {isMobile ? (
          <>
            <StickyUiLinks
              seoFriendlyUrl={seoFriendlyUrl}
              type={type}
              studyArea={studyArea}
            />
          </>
        ) : (
          <>
            <SideBarLinks />
            {type === 'unit' && <UnitDetails />}
          </>
        )}
      </CardGroup>
    </Secondary>
  );
};

SideBar.propTypes = sideBarPropTypes;

SideBar.defaultProps = {
  type: null,
};

export default SideBar;
