const onOpen = () => {
  const body = document.getElementsByTagName('BODY')[0];
  body.classList.add('filters-open');
};

const onClose = () => {
  const body = document.getElementsByTagName('BODY')[0];
  body.classList.remove('filters-open');
};

export { onOpen, onClose };
