import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import { halfScrollOverride } from './constants';

const Suggestion = ({ studyTypes, suggestion }) => {
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [suggestion]);

  if (!suggestion) {
    return null;
  }

  const params = new URLSearchParams({
    page: 1,
    study_type: studyTypes,
    search_text: suggestion,
  });
  return (
    <span className="search-form__suggestions">
      <p>
        Did you mean{' '}
        <Link
          ref={ref}
          to={{
            pathname: '/',
            search: params.toString(),
            state: halfScrollOverride,
          }}
          aria-label={`Did you mean ${suggestion}?`}
          aria-live="polite"
        >
          {suggestion}
        </Link>
        ?
      </p>
    </span>
  );
};

Suggestion.propTypes = {
  studyTypes: PropTypes.string.isRequired,
  suggestion: PropTypes.string,
};

Suggestion.defaultProps = {
  suggestion: null,
};

export default Suggestion;
