import { useState, useEffect } from 'react';
import { match } from 'css-mediaquery';

function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function onResizeEvent() {
      setIsMobile(
        /**
         * The query below mirrors the grid display condition
         * from study.curtin CSS.
         */
        !match('screen and (min-width: 64em)', {
          type: 'screen',
          width: window.innerWidth,
        }),
      );
    }
    window.addEventListener('resize', onResizeEvent);
    onResizeEvent();
    return () => window.removeEventListener('resize', onResizeEvent);
  });
  return isMobile;
}

export default useIsMobile;
