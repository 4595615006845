/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import PropTypes from 'prop-types';
import { useState } from 'react';

import { Select } from '../Common';

import './Location.scss';

const LocationsItem = ({ children, selected, year }) => (
  <div
    className="locations__item"
    data-campus={selected}
    style={{ display: selected === year ? 'block' : 'none' }}
  >
    <div className="locations__years two-or-more">{children}</div>
  </div>
);

LocationsItem.propTypes = {
  children: PropTypes.node.isRequired,
  selected: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
};

const LocationItem = ({ children, year }) => (
  <div className="locations__year">
    <h3 className="h2">{year}</h3>
    {children}
  </div>
);

LocationItem.propTypes = {
  children: PropTypes.node.isRequired,
  year: PropTypes.string.isRequired,
};

const StudyPeriods = ({ studyPeriod, attendanceModes }) => {
  const attendanceModesSorted = attendanceModes.sort((a, b) =>
    a > b ? 1 : -1,
  );
  return (
    <div className="locations__period">
      <h4>{studyPeriod}</h4>
      <ul className="locations__mode small">
        {attendanceModesSorted.map((mode) => (
          <li key={`${mode}`}>{mode}</li>
        ))}
      </ul>
    </div>
  );
};

StudyPeriods.propTypes = {
  studyPeriod: PropTypes.string.isRequired,
  attendanceModes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const Location = ({ options }) => {
  const selectedCampus = options.some((x) => x.campus === 'Curtin Perth')
    ? 'Curtin Perth'
    : `${options[0].campus}`;
  const [rawSelected, setSelected] = useState({
    value: selectedCampus,
    label: selectedCampus,
  });
  const selected = rawSelected.value;
  const selectOptions = options.reduce(
    (acc, location) => ({
      ...acc,
      [location.campus]: location,
    }),
    {},
  );
  return (
    <div className="handbook__location_select">
      <Select
        options={Object.keys(selectOptions).map((key) => ({
          label: key,
          value: key,
        }))}
        selected={rawSelected}
        onChange={setSelected}
        ariaLabel="Select campus"
      />
      {Object.entries(selectOptions).map(([key, { campusEntries }]) => (
        <LocationsItem key={key} selected={selected} year={key}>
          {campusEntries.map(({ year, yearEntries }) => (
            <LocationItem key={year} year={year}>
              {yearEntries.map(({ studyPeriod, studyPeriodEntries }) => (
                <StudyPeriods
                  key={studyPeriod}
                  studyPeriod={studyPeriod}
                  attendanceModes={studyPeriodEntries}
                />
              ))}
            </LocationItem>
          ))}
        </LocationsItem>
      ))}
    </div>
  );
};

Location.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      campus: PropTypes.string,
      campusEntries: PropTypes.arrayOf(
        PropTypes.shape({
          year: PropTypes.string,
          yearEntries: PropTypes.arrayOf(
            PropTypes.shape({
              studyPeriod: PropTypes.string,
              studyPeriodEntries: PropTypes.arrayOf(PropTypes.string),
            }),
          ),
        }),
      ),
    }),
  ).isRequired,
};

export default Location;
