import {
  SET_SEARCH_TERM,
  SET_RESULTS,
  SET_ERROR,
  SET_LOADING,
} from './constants';

function searchReducer(state, action) {
  switch (action.type) {
    case SET_SEARCH_TERM:
      return { ...state, searchTerm: action.value };
    case SET_RESULTS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        totalMatches: action.totalMatches,
        results: action.results,
        suggestion: action.suggestion,
        filters: action.filters,
      };
    case SET_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case SET_ERROR:
      return { ...state, isLoading: false, isError: true };
    default:
      return state;
  }
}

export default searchReducer;
