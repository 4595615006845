import StudyModeModal from './StudyModeModal';
import GetStudyMode from '../Utils/GetStudyMode';
import { Domestic, International } from '../Icons';

const StudyModeHeader = () => {
  const mode = GetStudyMode();

  return (
    <>
      <button
        id="study-mode-button"
        className="button--ico"
        data-personalisation-modal=""
        data-segment={mode.shortcode}
        type="button"
        aria-labelledby={`select-${mode.shortcode}`}
      >
        {mode.shortcode === 'dom' ? <Domestic /> : <International />}

        <span
          className="personalisation-toggle__label"
          id={`select-${mode.shortcode}`}
          aria-label="Change your region type"
        >
          {mode.name}
        </span>
      </button>

      <StudyModeModal currentMode={mode} />
    </>
  );
};

export default StudyModeHeader;
