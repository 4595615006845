import React from 'react';
import PropTypes from 'prop-types';

const StudyModeModalOptions = ({ currentMode }) => {
  const options = [
    {
      shortcode: 'dom',
      title: 'Study for domestic residents',
      detail:
        'Australian or New Zealand citizen, Australian permanent resident, or Australian permanent humanitarian visa holder.',
    },
    {
      shortcode: 'int',
      title: 'Study for international residents',
      detail:
        'Citizens or permanent residents of a country other than Australia or New Zealand.',
    },
  ];

  return options.map((option) => (
    <button
      className={
        currentMode.shortcode === option.shortcode
          ? 'box radio-group-button active'
          : 'box radio-group-button'
      }
      data-set-segment={`${option.shortcode}`}
      type="button"
      key={`${option.shortcode}`}
    >
      <span className="inner">
        <h3 className="box__title">
          <strong>{option.title}</strong>
        </h3>
        <span className="box__detail small">{option.detail}</span>
      </span>
    </button>
  ));
};

StudyModeModalOptions.propTypes = {
  currentMode: PropTypes.shape({
    shortcode: PropTypes.string,
  }).isRequired,
};

export default StudyModeModalOptions;
