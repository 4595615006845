import { useContext } from 'react';

import { StudyModeContext } from '../StudyModeContext';
import { ReadMoreParagraph } from '../Common';
import useIsMobile from '../Utils/isMobile';

import './Disclaimer.scss';

const MainContent = (shortcode) => (
  <>
    <p>
      <strong className="h3">Information for international students</strong>
      <span className="paragraph">
        If you are an international student studying on a student visa, you must
        undertake a full-time load in each study period. There may be additional{' '}
        {shortcode === 'dom' ? 'admission criteria' : 'entry requirements'} that
        must be met. For more information, you can refer to the{' '}
        <a
          href="https://www.curtin.edu.au/study/international-students/"
          target="_blank"
          rel="noreferrer"
        >
          International Students&apos;
        </a>{' '}
        website. If you do not hold a student visa or are studying outside of
        Australia, you have the option to study full-time, part-time and/or
        fully online depending on course and unit availabilities as well as your
        in-country visa requirements.
      </span>
      <strong className="h3">Acknowledgement of inherent requirements</strong>
      <span className="paragraph">
        I understand that all Curtin courses have compulsory and other core
        capabilities that are essential for demonstrating the achievement of
        course learning outcomes and graduation. If I am unable to meet or
        demonstrate those requirements, now or in later during my studies,
        reasonable adjustments will be sought by the University wherever
        possible to facilitate alternative ways of achieving of those
        requirements. If reasonable adjustments cannot be accommodated, Curtin
        will discuss study options to find an alternative course of study or an
        exit degree.
      </span>
      <strong className="h3">Student rights and responsibilities</strong>
      <span className="paragraph">
        As a Curtin University student, you are part of the Curtin community and
        as such, you have rights and responsibilities. Some relate to your work
        as a student, such as academic integrity and copyright. Others relate to
        your use of resources and the way you behave around other students.
        Students are expected to abide by the{' '}
        <a
          href="https://policies.curtin.edu.au/legislation/statutes_rules.cfm"
          target="_blank"
          rel="noreferrer"
        >
          University&apos;s statutes, rules, by-laws, policies and procedures
        </a>{' '}
        as amended from time to time. You can find further information about
        your{' '}
        <a
          href="https://students.curtin.edu.au/essentials/rights/"
          target="_blank"
          rel="noreferrer"
          aria-label="Student rights and responsibilities."
        >
          rights and responsibilities
        </a>{' '}
        on the Student Essentials website.
      </span>
      <strong className="h3">Disclaimer of liability</strong>
      <span className="paragraph">
        You can access the full{' '}
        <a
          href="https://www.curtin.edu.au/disclaimer-of-liability/"
          target="_blank"
          rel="noreferrer"
          aria-label="Disclaimer of liability."
        >
          disclaimer of liability
        </a>{' '}
        on the Study website.
      </span>
    </p>
  </>
);

const Disclaimer = () => {
  const isMobile = useIsMobile();
  const { shortcode } = useContext(StudyModeContext);

  return (
    <div className="page-components contained">
      <section className="support" id="disclaimer">
        {isMobile ? (
          <ReadMoreParagraph showMore>
            <div>{MainContent(shortcode)}</div>
          </ReadMoreParagraph>
        ) : (
          MainContent(shortcode)
        )}
      </section>
    </div>
  );
};

export default Disclaimer;
