import PropTypes from 'prop-types';

import { Select } from '../Common';

import './Version.scss';

const resolveFont = (content, tense) => {
  switch (tense) {
    case 'Future':
      return <strong>{content}</strong>;
    case 'Past':
      return <i>{content}</i>;
    default:
      return content;
  }
};

const versionOption = (selected) => {
  const { dateLabel, tense } = selected;
  return (
    <div
      className={`handbook__version_option handbook__version_option__${tense
        .toLowerCase()
        .replace(/\s+/g, '')}`}
    >
      <p>
        <small>{resolveFont(dateLabel, tense)}</small>
      </p>
    </div>
  );
};

const SelectVersion = ({ value, options, onChange }) => (
  <div className="handbook__version_select">
    <Select
      options={options}
      selected={value}
      onChange={onChange}
      showSelected={false}
      optionComponent={versionOption}
      ariaLabel="Select offering version"
    />
  </div>
);
SelectVersion.propTypes = {
  value: PropTypes.shape({
    value: PropTypes.string,
    dateLabel: PropTypes.string.isRequired,
    tense: PropTypes.string.isRequired,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      dateLabel: PropTypes.string.isRequired,
      tense: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

SelectVersion.defaultProps = {
  value: PropTypes.shape({ value: null }),
};

export default SelectVersion;
