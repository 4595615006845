import { Children, isValidElement, cloneElement } from 'react';

function recursiveClone(children, propsFilterFuncs) {
  return Children.map(children, (child) => {
    const [, injectProps] = propsFilterFuncs.find(([func]) => func(child)) || [
      null,
      {},
    ];
    return isValidElement(child)
      ? cloneElement(
          child,
          injectProps,
          recursiveClone(child.props.children, propsFilterFuncs),
        )
      : child;
  });
}

export default recursiveClone;
