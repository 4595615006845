import { useState } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

import { Times } from '../Icons';

import './Modal.scss';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '1rem',
    padding: '0',
    background: 'rgb(247, 247, 247)',
  },
  overlay: {
    background: 'hsla(0,0%,70.2%,.45)',
  },
};

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#content-wrapper');

const CloseButton = ({ onClick }) => (
  <button type="button" title="Close" className="button--ico" onClick={onClick}>
    <Times />
  </button>
);

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const CustomModal = ({
  buttonValue,
  customButtonStyles,
  headerValue,
  children,
}) => {
  const [isOpen, setOpen] = useState(false);
  function openModal() {
    setOpen(true);
  }
  function closeModal() {
    setOpen(false);
  }

  const className = `handbook__button_as_link${
    customButtonStyles ? ` ${customButtonStyles}` : ''
  }`;

  return (
    <>
      <button onClick={openModal} className={className} type="button">
        {buttonValue}
      </button>
      <Modal
        isOpen={isOpen}
        style={customStyles}
        onRequestClose={closeModal} // eslint-disable-line react/jsx-no-bind
        closeTimeoutMS={200}
        aria-labelledby="handbook_modal_heading"
      >
        <div className="handbook__modal_header">
          <h3 id="handbook_modal_heading">{headerValue}</h3>
          {/* eslint-disable-next-line react/jsx-no-bind */}
          <CloseButton onClick={closeModal} />{' '}
        </div>
        <div className="handbook__modal_content handbook__with_scrollbar">
          {children}
        </div>
      </Modal>
    </>
  );
};

CustomModal.propTypes = {
  buttonValue: PropTypes.string.isRequired,
  customButtonStyles: PropTypes.string,
  headerValue: PropTypes.string.isRequired,
  children: PropTypes.node,
};

CustomModal.defaultProps = {
  customButtonStyles: '',
  children: null,
};

export default CustomModal;
