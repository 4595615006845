import PropTypes from 'prop-types';

const CardContent = ({ children }) => (
  <div className="card__content">{children}</div>
);

CardContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CardContent;
