import PropTypes from 'prop-types';

import { Search } from '../../Icons';

const SearchButton = ({ onClick, disabled, label }) => (
  <button
    type="button"
    className="search-form__submit search-submit"
    tabIndex="-1"
    onClick={onClick}
    disabled={disabled}
    aria-label={label}
  >
    <Search />
  </button>
);

SearchButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
};

SearchButton.defaultProps = {
  disabled: false,
};

export default SearchButton;
