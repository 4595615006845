import PropTypes from 'prop-types';

import './SearchPage.scss';

const SearchPage = ({ children, loading }) => (
  <div className={`search-page${loading ? ' loading top' : ''}`}>
    <div id="page-content">
      <div className="search">{children}</div>
    </div>
  </div>
);

SearchPage.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
};

SearchPage.defaultProps = {
  loading: false,
};

export default SearchPage;
