import PropTypes from 'prop-types';

const CardTitle = ({ children }) => (
  <div className="card__title">
    <h2 className="h3">{children}</h2>
  </div>
);

CardTitle.propTypes = {
  children: PropTypes.string.isRequired,
};

export default CardTitle;
