import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import { GreaterThanBar, GreaterThan } from '../../../Icons';
import getDisplayRange from '../utils';

const PageLink = ({ createLink, number, isActive }) => (
  <Link
    to={createLink(number)}
    aria-label={`search results page ${number}`}
    aria-current={isActive ? 'page' : ''}
    className={`search-pagination__page${isActive ? ' active' : ''}`}
  >
    {number}
  </Link>
);

PageLink.propTypes = {
  createLink: PropTypes.func.isRequired,
  number: PropTypes.number.isRequired,
  isActive: PropTypes.bool,
};

PageLink.defaultProps = {
  isActive: false,
};

const Pagination = ({ total, searchState: { pageQuery } }) => {
  const location = useLocation();
  const currentPage = parseInt(pageQuery, 10);
  const maxPage = Math.ceil(total / 10);

  const createLink = (number) => {
    const params = new URLSearchParams(location.search);
    params.set('page', number);
    return { pathname: '/', search: params.toString() };
  };

  return (
    <div className="search-pagination">
      {currentPage > 1 && (
        <>
          <Link
            className="search-pagination__first"
            aria-label="search results first page"
            to={createLink(1)}
          >
            <GreaterThanBar />
          </Link>
          <Link
            className="search-pagination__prev"
            aria-label="search results previous page"
            to={createLink(currentPage - 1)}
          >
            <GreaterThan />
          </Link>
        </>
      )}
      <div className="search-pagination__pages">
        {getDisplayRange(currentPage, maxPage).map((value) => (
          <PageLink
            key={value}
            createLink={createLink}
            number={value}
            isActive={value === currentPage}
          />
        ))}
        {maxPage > 5 && currentPage < maxPage - 2 && <span>...</span>}
      </div>
      {currentPage < maxPage && (
        <>
          <Link
            className="search-pagination__next"
            aria-label="search results next page"
            to={createLink(currentPage + 1)}
          >
            <GreaterThan />
          </Link>
          <Link
            className="search-pagination__next"
            aria-label="search results last page"
            to={createLink(maxPage)}
          >
            <GreaterThanBar />
          </Link>
        </>
      )}
    </div>
  );
};

Pagination.propTypes = {
  total: PropTypes.number.isRequired,
  searchState: PropTypes.shape({
    pageQuery: PropTypes.string.isRequired,
    studyTypeQuery: PropTypes.string.isRequired,
    searchValueQuery: PropTypes.string.isRequired,
  }).isRequired,
};

export default Pagination;
