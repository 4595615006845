import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

import App from './components/App/App';
import StudyModeHeader from './components/StudyMode/StudyModeHeader';

Amplify.configure(awsconfig);

ReactDOM.render(<App />, document.querySelector('#content-wrapper'));
ReactDOM.render(
  <StudyModeHeader />,
  document.querySelector('#personalisation-toggle'),
);
