import PropTypes from 'prop-types';
import StudyModeModalOptions from './StudyModeModalOptions';

const StudyModeModal = ({ currentMode }) => (
  <div
    id="personalisation-modal"
    role="dialog"
    aria-labelledby="dialog_label"
    aria-modal="true"
    className="personalisation-modal modal"
    style={{ display: 'none' }}
  >
    <h2 id="dialog_label" className="h1 personalisation-modal__title body-font">
      What kind of study are you interested in?
    </h2>
    <div className="modal__content radio-group radio-group--full-width">
      <StudyModeModalOptions currentMode={currentMode} />
    </div>
  </div>
);

StudyModeModal.propTypes = {
  currentMode: PropTypes.shape({
    shortcode: PropTypes.string,
  }).isRequired,
};

export default StudyModeModal;
