function getDisplayRange(currentPage, maxPage) {
  const range = [...Array(Math.min(maxPage, 5)).keys()];
  if (currentPage <= 3 || maxPage < 5) {
    return range.map((value) => value + 1);
  }
  if (currentPage > maxPage - 3) {
    return range.map((value) => value - 4 + maxPage);
  }
  return range.map((value) => value + currentPage - 2);
}

export default getDisplayRange;
