import PropTypes from 'prop-types';

import useIsMobile from '../../../Utils/isMobile';
import { onOpen } from '../mobileFilterHooks';
import { Filter } from '../../../Icons';

const MobileFilters = ({ filterCount }) => {
  const isMobile = useIsMobile();
  return (
    isMobile && (
      <div className="search-button-group">
        <button onClick={onOpen} type="button" aria-label="Filter results">
          <Filter />
          <span>Filter</span>
          <span className="mobile-filters__count">{filterCount}</span>
        </button>
      </div>
    )
  );
};

MobileFilters.propTypes = {
  filterCount: PropTypes.number.isRequired,
};

const TotalResults = ({ value, filterCount }) => {
  const valueString = `${value} ${value === 1 ? 'match' : 'matches'}`;
  return (
    <div className="search-results__header search-header">
      {valueString}
      <MobileFilters filterCount={filterCount} />
    </div>
  );
};

TotalResults.propTypes = {
  value: PropTypes.number.isRequired,
  filterCount: PropTypes.number.isRequired,
};

export default TotalResults;
