import { Page, Primary, Header } from '../Common';

const LoadingMessage = () => (
  <Page>
    <Primary>
      <Header title="Loading..." />
    </Primary>
  </Page>
);

export default LoadingMessage;
