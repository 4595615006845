import PropTypes from 'prop-types';

const TernaryParagraph = ({ children }) => children && <p>{children}</p>;

TernaryParagraph.propTypes = {
  children: PropTypes.string,
};

TernaryParagraph.defaultProps = {
  children: null,
};

export default TernaryParagraph;
