import { useCookies } from 'react-cookie';

const GetStudyMode = () => {
  const [cookies] = useCookies(['segment']);

  return cookies.segment === 'dom' || cookies.segment === undefined
    ? { shortcode: 'dom', name: 'Domestic' }
    : { shortcode: 'int', name: 'International' };
};

export default GetStudyMode;
