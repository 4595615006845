import PropTypes from 'prop-types';

import './Page.scss';

const Page = ({ children }) => (
  <div id="page-content" className="contained">
    {children}
  </div>
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Page;
