import SupportMessage from '../../../Message/SupportMessage';

const ErrorMessage = () => (
  <div className="search__no-results" role="alert">
    <h2>Something went wrong.</h2>
    <SupportMessage />
  </div>
);

export default ErrorMessage;
