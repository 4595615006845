import PropTypes from 'prop-types';

import './Header.scss';

const Header = ({ title }) => (
  <div className="block-heading block">
    <h1 className="block-header__title h1 h-underline">{title}</h1>
  </div>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Header;
