import PropTypes from 'prop-types';

import { Card, CardTitle, CardContent } from '../Common';
import { Info } from '../Icons';

import './UnitDetails.scss';

const unitDetailsInfo = `You can access the most up to date information and unit references,
texts, outcomes, assessment details and tuition patterns in the Unit
Outline.`;

const UnitDetails = ({ isMobile }) => (
  <>
    {isMobile ? (
      <div
        className="page-components contained"
        id="unit-details-info-component"
      >
        <section className="support" id="unit-details-info">
          <Info />
          <h3>Unit details information</h3>
          {unitDetailsInfo}
        </section>
      </div>
    ) : (
      <Card>
        <Info />
        <CardTitle>Unit details information</CardTitle>
        <CardContent>
          <p>{unitDetailsInfo}</p>
        </CardContent>
      </Card>
    )}
  </>
);

UnitDetails.propTypes = {
  isMobile: PropTypes.bool,
};

UnitDetails.defaultProps = {
  isMobile: false,
};

export default UnitDetails;
