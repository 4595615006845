import PropTypes from 'prop-types';

const summaryPropTypes = {
  summaryOptions: PropTypes.arrayOf(PropTypes.array),
};

const sideBarPropTypes = {
  sideBarOptions: PropTypes.shape({
    title: PropTypes.string,
    studyArea: PropTypes.string,
    seoFriendlyUrl: PropTypes.string,
    udc: PropTypes.string,
  }),
  summaryOptions: PropTypes.arrayOf(PropTypes.array),
  type: PropTypes.string,
};

export { sideBarPropTypes, summaryPropTypes };
