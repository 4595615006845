import { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const PageNameContext = createContext({
  pageName: null,
  setPageName: () => null,
});

export const PageNameProvider = ({ children }) => {
  const [pageName, setPageName] = useState(null);
  return (
    <PageNameContext.Provider value={{ pageName, setPageName }}>
      {children}
    </PageNameContext.Provider>
  );
};

PageNameProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
