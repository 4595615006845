import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function getElement(hash) {
  const id = hash.slice(1);
  if (!id) {
    return null;
  }
  return document.getElementById(id);
}

function calculateTop(element) {
  const { top } = element.getBoundingClientRect();
  return top + window.pageYOffset - 100;
}

function createScrollTimeout(top) {
  setTimeout(() => window.scrollTo({ top, behavior: 'smooth' }), 50);
}

export default function ScrollToTop() {
  const { pathname, hash, state } = useLocation();

  useEffect(() => {
    if (state && state.scrollOverride) {
      createScrollTimeout(state.scrollOverride);
      return;
    }

    const element = getElement(hash);
    if (element) {
      element.focus();
      createScrollTimeout(calculateTop(element));
    }
  });

  useEffect(() => {
    createScrollTimeout(0);
  }, [pathname]);

  return null;
}
