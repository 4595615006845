import './FakeTableHeader.scss';

const FakeTableHeader = () => (
  <div className="handbook__fake_table_header" aria-hidden>
    <span>Code</span>
    <span>Title</span>
  </div>
);

export default FakeTableHeader;
