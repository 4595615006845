import PropTypes from 'prop-types';

import './Primary.scss';

const Primary = ({ children }) => (
  <article className="content-container blocks">{children}</article>
);

Primary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Primary;
