import PropTypes from 'prop-types';

import './Icons.scss';

const Briefcase = () => (
  <span className="ico ico-briefcase ico--lime" aria-label="briefcase">
    <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path d="M22.3 8A7 7 0 0 0 9.7 8H2v18h28V8zM16 6a5 5 0 0 1 4 2h-8a5 5 0 0 1 4-2zm12 4v4H4v-4zM4 24v-8h4v2h2v-2h12v2h2v-2h4v8z" />
    </svg>
  </span>
);

const International = () => (
  <span className="ico" aria-label="international">
    <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path d="M27.2 7.6A14 14 0 1 0 30 16a13.88 13.88 0 0 0-2.8-8.4zM10.5 16a25.77 25.77 0 0 1 .21-3.26 24.45 24.45 0 0 0 4.29.51v5.5a24.45 24.45 0 0 0-4.29.51A25.77 25.77 0 0 1 10.5 16zM17 4.22c1.61.71 3.14 3.08 3.93 6.55a23.21 23.21 0 0 1-3.93.48zm-2 0v7a23.21 23.21 0 0 1-3.93-.48c.79-3.44 2.32-5.81 3.93-6.52zm0 16.53v7c-1.61-.71-3.14-3.08-3.93-6.55a23.21 23.21 0 0 1 3.93-.45zm2 7v-7a23.21 23.21 0 0 1 3.93.48c-.79 3.47-2.32 5.84-3.93 6.55zm0-9v-5.5a24.45 24.45 0 0 0 4.29-.51A25.77 25.77 0 0 1 21.5 16a25.77 25.77 0 0 1-.21 3.26 24.45 24.45 0 0 0-4.29-.51zm6.23-6.52a14.92 14.92 0 0 0 3.51-1.58A12 12 0 0 1 28 16a11.84 11.84 0 0 1-1.27 5.34 15.44 15.44 0 0 0-3.5-1.57A26.86 26.86 0 0 0 23.5 16a26.86 26.86 0 0 0-.27-3.77zM25.6 8.8l.1.14a12.52 12.52 0 0 1-2.85 1.3 15.62 15.62 0 0 0-2.21-5.3A11.88 11.88 0 0 1 25.6 8.8zM11.37 4.93a15.68 15.68 0 0 0-2.22 5.31A12.43 12.43 0 0 1 6.31 9a12.11 12.11 0 0 1 5.06-4.07zm-6.1 5.73a15.44 15.44 0 0 0 3.5 1.57 26.46 26.46 0 0 0 0 7.54 15.44 15.44 0 0 0-3.5 1.57 11.86 11.86 0 0 1 0-10.68zm1 12.39a12.43 12.43 0 0 1 2.84-1.29 15.68 15.68 0 0 0 2.22 5.31 12.11 12.11 0 0 1-5.02-4.02zm14.32 4a15.68 15.68 0 0 0 2.22-5.31 12.43 12.43 0 0 1 2.84 1.29 12.11 12.11 0 0 1-5.02 4.04z" />
    </svg>
  </span>
);

const Domestic = () => (
  <span className="ico" aria-label="domestic">
    <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path d="M22.55 27.37L17 22.55 4.2 25.84 4 22.55.76 14.3l5.17-3.15 3.52-5.91 1.81.9 2.64-2.95 5.21 1.21-.61 1.94 1.64 1.24 1.51-6.13 5.18 9.62 4.28 5.25-3 10.3zm-3.78-5.9l4.41 3.8 3.37-.46 2.34-8-3.77-4.71L22.35 7l-1 4-5.18-3.92.38-1.22-2-.46-2.81 3.22-1.52-.76L7.4 12.6l-4.16 2.53 2.75 7 .07 1.19 13.11-3.38zM24.22 29.2l2.48 2.59 1.6-1.13-.3-2.08-3.78.62z" />
    </svg>
  </span>
);

const Search = ({ colour }) => (
  <span className={`ico ico-magnify ico--${colour}`} aria-label="search">
    <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path d="M26.66 5.34A11.4 11.4 0 0 0 9.87 20.72l-7.58 7.57 1.42 1.42 7.57-7.58A11.4 11.4 0 0 0 26.66 5.34zm-1.41 14.71a9.4 9.4 0 1 1 0-13.3 9.43 9.43 0 0 1 0 13.3z" />
    </svg>
  </span>
);

Search.propTypes = {
  colour: PropTypes.oneOf(['deep-blue', 'gray']),
};

Search.defaultProps = {
  colour: 'deep-blue',
};

const GreaterThanBar = () => (
  <span className="ico ico-end ico--gray-dark" aria-label="greater than bar">
    <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path d="M6.29 6.71l9.3 9.29-9.3 9.29 1.42 1.42L18.41 16 7.71 5.29 6.29 6.71zM23 6h2v20h-2z" />
    </svg>
  </span>
);

const GreaterThan = () => (
  <span className="ico ico-chevron ico--gray-dark" aria-label="greater than">
    <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path d="M11.71 26.71l-1.42-1.42 9.3-9.29-9.3-9.29 1.42-1.42L22.41 16l-10.7 10.71z" />
    </svg>
  </span>
);

const Times = () => (
  <span className="ico ico-chevron ico--black" aria-label="times">
    <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path d="M25.71 7.71l-1.42-1.42-8.29 8.3-8.29-8.3-1.42 1.42 8.3 8.29-8.3 8.29 1.42 1.42 8.29-8.3 8.29 8.3 1.42-1.42-8.3-8.29 8.3-8.29z" />
    </svg>
  </span>
);

const Reset = () => (
  <span className="ico ico-reset ico--gray-dark" aria-label="reset">
    <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path d="M31.71 14.29L27 9.59l-4.71 4.7 1.42 1.42 2.29-2.3V16A11 11 0 1 1 15 5V3a13 13 0 1 0 13 13v-2.59l2.29 2.3z" />
    </svg>
  </span>
);

const Info = () => (
  <span className="ico ico-info ico--lime">
    <svg
      xmlns="https://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      aria-label="information"
    >
      <path d="M16,2C8.3,2,2,8.3,2,16s6.3,14,14,14s14-6.3,14-14S23.7,2,16,2z M16,28C9.4,28,4,22.6,4,16S9.4,4,16,4s12,5.4,12,12S22.6,28,16,28z" />
      <path d="M15,15h2v6h-2V15z" />
      <circle cx="16" cy="12" r="1" />
    </svg>
  </span>
);

const Home = ({ label }) => (
  <span className="ico ico-home" aria-label={label}>
    <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path d="M16 1.74L2 12.51V30h28V12.51zM28 28H4V13.49l12-9.23 12 9.23z" />
    </svg>
  </span>
);

Home.propTypes = {
  label: PropTypes.string,
};

Home.defaultProps = {
  label: null,
};

const Filter = () => (
  <span
    className="ico ico-filter ico--deep-blue ico--small"
    aria-label="filter"
  >
    <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path d="M17.86 9a4 4 0 1 0 0 2H27V9zM14 12a2 2 0 1 1 2-2 2 2 0 0 1-2 2zM5 9h3v2H5zM24 21h3v2h-3zM18 18a4 4 0 0 0-3.86 3H5v2h9.14A4 4 0 1 0 18 18zm0 6a2 2 0 1 1 2-2 2 2 0 0 1-2 2z" />
    </svg>
  </span>
);

const Close = () => (
  <span className="ico ico-times ico--gray-dark" aria-label="close">
    <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path d="M25.71 7.71l-1.42-1.42-8.29 8.3-8.29-8.3-1.42 1.42 8.3 8.29-8.3 8.29 1.42 1.42 8.29-8.3 8.29 8.3 1.42-1.42-8.3-8.29 8.3-8.29z" />
    </svg>
  </span>
);

const Download = () => (
  <span className="ico" aria-label="download">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -3 32 32">
      <path
        id="Path_235"
        data-name="Path 235"
        d="M24.4,11,23,9.6l-4.3,4.3V6.3h-2v7.6L12.4,9.6,11,11l6.7,6.7Z"
        fill="#fff"
      />
      <path
        id="Path_236"
        data-name="Path 236"
        d="M30.7,16.7v9H4.7v-9h2v7h22v-7Z"
        fill="#fff"
      />
    </svg>
  </span>
);

const Book = ({ colour }) => (
  <span className={`ico ico--${colour}`} aria-label="book">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        id="Path_233"
        data-name="Path 233"
        d="M24.017,5H9.883a.883.883,0,1,0,0,1.767H24.017a.883.883,0,1,0,0-1.767Z"
        transform="translate(-0.467 -0.467)"
      />
      <path
        id="Path_234"
        data-name="Path 234"
        d="M6.767,5.417a2.65,2.65,0,0,1,2.65-2.65H23.55A.883.883,0,0,0,23.55,1H9.417A4.417,4.417,0,0,0,5,5.417v18.55A3.533,3.533,0,0,0,8.533,27.5h15.9V8.067H9.417A2.65,2.65,0,0,1,6.767,5.417Zm0,18.55V8.95a4.417,4.417,0,0,0,2.65.883v15.9H8.533A1.767,1.767,0,0,1,6.767,23.967Zm15.9,1.767H11.183V9.833H22.667Z"
        transform="translate(0)"
      />
    </svg>
  </span>
);

Book.propTypes = {
  colour: PropTypes.oneOf(['0', 'white']),
};

Book.defaultProps = {
  colour: '0',
};

const Options = ({ colour }) => (
  <span className={`ico ico--${colour}`} aria-label="options">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path
        id="ico-options"
        d="M24.46,11.05l-1.41,1.41L25.59,15H22a7,7,0,0,0-5,2.11V6.41L19.54,9,21,7.54,16,2.59l-4.95,5L12.46,9,15,6.41V23.59L7.41,16H11V14H4v7H6V17.41l9,9V32h2V22a5,5,0,0,1,5-5h3.59l-2.54,2.54L24.46,21l4.95-5Z"
        transform="translate(-4 -2.59)"
      />
    </svg>
  </span>
);

Options.propTypes = {
  colour: PropTypes.oneOf(['0', 'white']),
};

Options.defaultProps = {
  colour: '0',
};

export {
  Briefcase,
  International,
  Domestic,
  Search,
  GreaterThanBar,
  GreaterThan,
  Times,
  Reset,
  Info,
  Home,
  Filter,
  Close,
  Download,
  Book,
  Options,
};
