import PropTypes from 'prop-types';

const unitRowProps = PropTypes.shape({
  code: PropTypes.string.isRequired,
  credits: PropTypes.string.isRequired,
  unitTitle: PropTypes.string,
  offeringLink: PropTypes.string,
});

const electiveGroupsProps = PropTypes.arrayOf(
  PropTypes.shape({
    structureComponent: PropTypes.string.isRequired,
    maxCredits: PropTypes.string.isRequired,
    structureComponentEntries: PropTypes.arrayOf(unitRowProps),
  }),
);

const electiveV0GroupsProps = PropTypes.shape({
  totalCredits: PropTypes.number.isRequired,
});

const courseStructureProps = PropTypes.arrayOf(
  PropTypes.shape({
    yearLevel: PropTypes.string.isRequired,
    yearLevelEntries: PropTypes.arrayOf(
      PropTypes.shape({
        studyPeriod: PropTypes.string.isRequired,
        studyPeriodEntries: PropTypes.shape({
          core: PropTypes.arrayOf(unitRowProps),
          electiveGroups: PropTypes.shape({
            core: electiveGroupsProps,
            options: electiveGroupsProps,
          }),
        }),
      }),
    ),
  }),
);

export { electiveV0GroupsProps, courseStructureProps };
