import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { RadioGroup, Modal } from '../../Common';
import { Reset, Close } from '../../Icons';
import { onClose } from './mobileFilterHooks';
import useIsMobile from '../../Utils/isMobile';

import './SideBar.scss';

const sortOptions = (a, b) => {
  const labelA = a.label;
  const labelB = b.label;
  if (labelA < labelB) {
    return -1;
  }
  if (labelA > labelB) {
    return 1;
  }
  return 0;
};

const StudyLevelModal = () => (
  <Modal
    buttonValue="Learn about study levels"
    customButtonStyles="pink"
    headerValue="Study levels"
  >
    <h3>Undergraduate</h3>
    <p>
      You are an undergraduate student if you have never completed a university
      degree before. There are plenty of pathways into undergraduate study,
      depending on your study or work background.
    </p>
    <h3>Postgraduate</h3>
    <p>
      You’re a postgraduate student if you have already completed a university
      degree and want to study at a higher level, to advance your career or
      switch careers.
    </p>
    <h3>Research</h3>
    <p>
      Undertake independent, original research at postgraduate level under the
      supervision of a senior academic or get involved in our acclaimed
      industry-focused research projects.
    </p>
    <h3>Bridging and enabling courses</h3>
    <p>
      If you don&apos;t meet the criteria for an undergraduate course, you can
      pursue a bridging and enabling course instead. These courses help you
      obtain the results you need to study and provide a pathway to qualify you
      for your chosen course.
    </p>
    <h3>Micro online courses</h3>
    <p>
      If you’re looking for a shorter course, try one of our edX online options.
      Studying a Curtin edX program is ideal if you are looking to upskill in a
      particular area.
    </p>
  </Modal>
);

const studyLevelSortOptions = (a, b) => a.displayOrder - b.displayOrder;

const optionType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  displayOrder: PropTypes.number,
});

const optionsTypes = PropTypes.arrayOf(optionType);

const FilterBlock = ({
  title,
  infoLinkText,
  options,
  selected,
  groupLabel,
  selectAll,
  inputLabel,
  buttonLabel,
  onChange,
  isLoading,
}) => (
  <fieldset className={`filter${isLoading ? ' loading' : ''}`}>
    <legend className={`h4 filter__title${infoLinkText ? '--less' : ''}`}>
      {title}
    </legend>
    {infoLinkText && (
      <div className="study_level__modal">
        <StudyLevelModal />
      </div>
    )}
    {options && (
      <RadioGroup
        selected={selected}
        options={options.sort(
          groupLabel === 'study_level' ? studyLevelSortOptions : sortOptions,
        )}
        onChange={onChange}
        groupLabel={groupLabel}
        selectAll={selectAll}
        inputLabel={inputLabel}
        buttonLabel={buttonLabel}
      />
    )}
  </fieldset>
);

FilterBlock.propTypes = {
  title: PropTypes.string.isRequired,
  infoLinkText: PropTypes.string,
  options: optionsTypes,
  selected: PropTypes.string,
  groupLabel: PropTypes.string.isRequired,
  selectAll: optionType.isRequired,
  inputLabel: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

FilterBlock.defaultProps = {
  infoLinkText: '',
  selected: null,
  options: null,
};

const ResetButton = ({ onClick }) => (
  <div className="search-header__button">
    <button
      type="button"
      data-filter-reset=""
      aria-label="Reset filters"
      onClick={onClick}
    >
      <Reset />
    </button>
  </div>
);

ResetButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const FiltersCloseButton = () => (
  <button className="search__filters-close" type="button" onClick={onClose}>
    <Close />
  </button>
);

const FiltersDoneButton = () => (
  <button
    onClick={onClose}
    className="search__device-submit handbook__mobile_filters_done"
    type="button"
  >
    Done
  </button>
);

const SideBar = ({
  filters: { studyLevels, fieldsOfEducation, locations, attendanceModes },
  filterValues: { studyLevel, fieldOfEducation, location, attendanceMode },
  onFilterChange,
  onResetFilters,
  isLoading,
}) => {
  const { search } = useLocation();
  const isMobile = useIsMobile();

  return (
    <div className="search__sidebar">
      <div className="search__filters-wrap">
        <div className="search-header">
          <h2 className="h3 search-header__title">Filter</h2>
          <ResetButton onClick={onResetFilters} />
          {isMobile && <FiltersCloseButton />}
        </div>
        <div className="search__filters">
          <Link
            to={{ hash: '#search-results-first', search }}
            className="sr-jump"
            onClick={isMobile ? onClose : null}
          >
            Skip to results
          </Link>
          <FilterBlock
            title="Study level"
            infoLinkText="Learn about study levels"
            options={studyLevels}
            selected={studyLevel}
            groupLabel="study_level"
            selectAll={{ label: 'All study levels', value: null }}
            inputLabel="Filter study levels options"
            buttonLabel="filter"
            onChange={(value) => onFilterChange('study_level', value)}
            isLoading={isMobile && isLoading}
          />
          <FilterBlock
            title="Field of education"
            options={fieldsOfEducation}
            selected={fieldOfEducation}
            groupLabel="field_of_education"
            selectAll={{ label: 'All fields of education', value: null }}
            inputLabel="Filter field of education options"
            buttonLabel="filter"
            onChange={(value) => onFilterChange('field_of_education', value)}
            isLoading={isMobile && isLoading}
          />
          <FilterBlock
            title="Attendance modes"
            options={attendanceModes}
            selected={attendanceMode}
            groupLabel="attendance_modes"
            selectAll={{ label: 'All attendance modes', value: null }}
            inputLabel="Filter attendance mode options"
            buttonLabel="filter"
            onChange={(value) => onFilterChange('attendance_mode', value)}
            isLoading={isMobile && isLoading}
          />
          <FilterBlock
            title="Location"
            options={locations}
            selected={location}
            groupLabel="location"
            selectAll={{ label: 'All locations', value: null }}
            inputLabel="Filter location options"
            buttonLabel="filter"
            onChange={(value) => onFilterChange('location', value)}
            isLoading={isMobile && isLoading}
          />
        </div>
        {isMobile && <FiltersDoneButton />}
      </div>
    </div>
  );
};

SideBar.propTypes = {
  filters: PropTypes.shape({
    studyLevels: optionsTypes,
    fieldsOfEducation: optionsTypes,
    locations: optionsTypes,
    attendanceModes: optionsTypes,
  }),
  filterValues: PropTypes.shape({
    studyLevel: PropTypes.string,
    fieldOfEducation: PropTypes.string,
    location: PropTypes.string,
    attendanceMode: PropTypes.string,
  }).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onResetFilters: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

SideBar.defaultProps = {
  filters: {
    studyLevels: null,
    fieldsOfEducation: null,
    locations: null,
    attendanceMode: null,
  },
};

export default SideBar;
