import PropTypes from 'prop-types';

import './Boolean.scss';

const Boolean = ({ operator }) => (
  <div className={`handbook__boolean ${operator.toLowerCase()}`} aria-hidden>
    {operator}
  </div>
);

Boolean.propTypes = {
  operator: PropTypes.oneOf(['AND', 'OR']).isRequired,
};

export default Boolean;
