const SupportMessage = () => (
  <p>
    Please contact the <strong>IT Helpdesk</strong> on{' '}
    <strong>+61 8 9266 9000</strong> (ext #9000) or report the issue in{' '}
    <a
      href="https://supportu.curtin.edu.au/sp/?id=sc_cat_item&sys_id=09072385db74d8105c7984a214961991"
      target="_blank"
      rel="noreferrer"
    >
      SupportU
    </a>
  </p>
);

export default SupportMessage;
