const linkPattern = /(https?:\/\/[^\s]*[^.\s)(])/g;

const splitByLinks = (text) => text.split(linkPattern);

const renderlinks = (text) =>
  text && (
    <>
      {splitByLinks(text).map((content, index) =>
        (index - 1) % 2 === 0 ? (
          <a key={content} href={content} rel="noreferrer" target="_blank">
            {content}
          </a>
        ) : (
          content
        ),
      )}
    </>
  );

export default renderlinks;
