import PropTypes from 'prop-types';

import './ButtonGroup.scss';

const ButtonGroup = ({ selected, options, onChange, disabled }) => (
  <section
    className="handbook__button-group"
    role="complementary"
    aria-label="Button block"
  >
    {Object.entries(options).map(([key, value]) => (
      <button
        key={key}
        type="button"
        className={`button button--deep-blue${
          value === selected ? '' : ' inactive'
        }`}
        onClick={() => onChange(value)}
        disabled={disabled}
      >
        {key}
      </button>
    ))}
  </section>
);

ButtonGroup.propTypes = {
  selected: PropTypes.string.isRequired,
  options: PropTypes.objectOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ButtonGroup.defaultProps = {
  disabled: false,
};

export default ButtonGroup;
