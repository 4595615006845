import PropTypes from 'prop-types';
import { useRef } from 'react';

import './SearchInput.scss';

const SearchInput = ({
  id,
  value,
  onChange,
  disabled,
  placeholder,
  inputLabel,
  onBlur,
  children,
}) => {
  const ref = useRef();
  const blurRef = useRef();
  return (
    <div
      ref={ref}
      className="search-by-query"
      onBlur={(e) => {
        blurRef.current = setTimeout(() => onBlur(ref, e), 50);
      }}
      onFocus={() => clearTimeout(blurRef.current)}
    >
      <input
        id={id}
        type="search"
        className="search-input"
        autoComplete="off"
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        disabled={disabled}
        aria-label={inputLabel}
        maxLength={70}
      />
      {children}
    </div>
  );
};

SearchInput.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  inputLabel: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  children: PropTypes.node,
};

SearchInput.defaultProps = {
  id: null,
  disabled: false,
  placeholder: null,
  onBlur: () => null,
  children: null,
};

export default SearchInput;
