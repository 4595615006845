import moment from 'moment';

const versionSortOrder = ['Future', 'Current', 'Phasing Out', 'Past'];

const resolveTense = (stageCode) => {
  if (stageCode === 'PO') {
    return 'Phasing Out';
  }
  if (stageCode === 'DS') {
    return 'Past';
  }
  if (stageCode === 'PL') {
    return 'Future';
  }
  // AC
  return 'Current';
};

const resolveDateLabel = (stageCode, startDate) => {
  if (stageCode === 'AC') {
    return `Commencing  ${startDate.format('D MMMM YYYY')}`;
  }
  if (stageCode === 'PL') {
    return `Planned for ${startDate.format('MMMM YYYY')}`;
  }
  // PO and DS
  return `Commenced ${startDate.format('D MMMM YYYY')}`;
};

const parseVersion = ({
  seoFriendlyUrlSuffix,
  effectiveDate,
  discontinuationDate,
  version,
  stageCode,
}) => {
  const startDate = moment(effectiveDate);
  const endDate = moment(discontinuationDate);
  return {
    dateLabel: `${resolveDateLabel(stageCode, startDate)}`,
    versionLabel: `Version ${version} (${startDate.format('D MMM YYYY')} - ${
      discontinuationDate ? endDate.format('D MMM YYYY') : 'onward'
    })`,
    value: seoFriendlyUrlSuffix,
    tense: resolveTense(stageCode),
  };
};

export { versionSortOrder, parseVersion };
