import PropTypes from 'prop-types';

import './PreWrap.scss';

const PreWrap = ({ children }) => (
  <div className="pre_wrap_paragraph">{children}</div>
);

PreWrap.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PreWrap;
