import GetStudyMode from '../../../Utils/GetStudyMode';
import { Domestic, International } from '../../../Icons';

const onStudyModeChangeClick = () => {
  const button = document.getElementById('study-mode-button');
  button.click();
};

const StudyModeNotification = () => {
  const mode = GetStudyMode();
  return (
    <div className="notification notification--notice small" data-segment="dom">
      {mode.shortcode === 'dom' ? <Domestic /> : <International />}
      <p>
        Content has been tailored for{' '}
        {mode.shortcode === 'dom' ? 'domestic' : 'international'} visitors.{' '}
        <button
          className="handbook__button_as_link"
          type="button"
          onClick={onStudyModeChangeClick}
        >
          Change your region.
        </button>
      </p>
    </div>
  );
};

export default StudyModeNotification;
