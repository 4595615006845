import converter from 'number-to-words';

import plural from '../../../Utils/plural';

const resolveRuleValue = (operator, operands) => {
  if (operator === 'CV') {
    return operands[0].ruleValue;
  }
  if (operator === 'NUM' || operator === 'ADM') {
    return Math.min(...operands.map(({ ruleValue }) => ruleValue));
  }
  return undefined;
};

const resolveCountToText = (operator, operandsLength, ruleValue) => {
  if (operator === 'AND' || operator === 'OR') {
    if (operandsLength > 1) {
      return {
        countText: operator === 'AND' ? 'all' : 'one',
        separator: 'of',
      };
    }
    return { countText: '', separator: '' };
  }

  if (operator === 'CV') {
    return {
      countText: converter.toWords(ruleValue),
      separator: '',
    };
  }

  if (ruleValue === 1 && operandsLength > 1) {
    return { countText: 'one', separator: 'of' };
  }

  if (ruleValue > 1 && operandsLength > 1) {
    return {
      countText: converter.toWords(ruleValue),
      separator: 'of',
    };
  }

  return { countText: '', separator: '' };
};

const renderPrefix = (operator) => {
  if (['CV', 'NUM', 'AND', 'OR'].includes(operator)) {
    return 'Successful completion of';
  }
  if (operator === 'ADM') {
    return 'Successful admission into';
  }
  return '';
};

const renderDescriptor = (operator, ruleValue, countText, separator) => {
  if (operator === 'CV') {
    return ` ${countText} credit ${plural(ruleValue, 'point')}`;
  }
  const renderedCountText = countText ? ` ${countText}` : '';
  const renderedSeparator = separator ? ` ${separator}` : '';
  return `${renderedCountText}${renderedSeparator}`;
};

const renderPreSuffix = (operator) => {
  if (['ADM', 'NUM', 'AND', 'OR'].includes(operator)) {
    return ' the following';
  }
  if (operator === 'CV') {
    return ' from the following';
  }
  return '';
};

const renderSuffix = (typeCode) => {
  if (typeCode === 'A') {
    return ' will prevent enrolment';
  }
  if (typeCode === 'C') {
    return ' must be completed in the same study period';
  }
  return '';
};

const renderHeadingComponents = (typeCode, operator, operands) => {
  const ruleValue = resolveRuleValue(operator, operands);
  const { countText, separator } = resolveCountToText(
    operator,
    operands.length,
    ruleValue,
  );
  return {
    ruleValue,
    countText,
    separator,
    prefix: renderPrefix(operator),
    descriptor: renderDescriptor(operator, ruleValue, countText, separator),
    preSuffix: renderPreSuffix(operator),
    suffix: renderSuffix(typeCode),
  };
};

const resolveSubHeading = (typeCode, operator, operands) => {
  if (typeCode === 'E') {
    return null;
  }

  const { prefix, descriptor, preSuffix, suffix } = renderHeadingComponents(
    typeCode,
    operator,
    operands,
  );

  return `${prefix}${descriptor}${preSuffix}${suffix}`;
};

export { renderHeadingComponents, resolveSubHeading };
