import { Router, Switch, Route, Redirect } from 'react-router-dom';
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from '@microsoft/applicationinsights-react-js';

import history from './history';
import { reactPlugin } from './AppInsights';

import { ErrorMessage } from '../Message';
import Home from '../Page/Home';
import CourseDetail from '../Page/CourseDetail';
import UnitDetail from '../Page/UnitDetail';

import { StudyModeProvider } from '../StudyModeContext';
import { PageNameProvider } from '../BreadCrumbs/PageNameProvider';
import { MobileApplyLinkProvider } from '../BreadCrumbs/MobileApplyLinkProvider';
import { NotifierProvider } from '../Notifier/NotifierProvider';
import ScrollToTop from '../Utils/ScrollToTop';
import BreadCrumbs from '../BreadCrumbs';
import Notifier from '../Notifier';
import StaticNamedRoute from './StaticNamedRoute';

import Help from '../Help';

import './App.scss';
import './AppPrint.scss';

const App = () => (
  <AppInsightsContext.Provider value={reactPlugin}>
    <NotifierProvider>
      <Notifier />
      <AppInsightsErrorBoundary
        onError={() => <ErrorMessage />}
        appInsights={reactPlugin}
      >
        <StudyModeProvider>
          <PageNameProvider>
            <MobileApplyLinkProvider>
              <Router history={history}>
                <ScrollToTop />
                <BreadCrumbs />
                <Switch>
                  <Route
                    path="/courses/:seoFriendlyUrl"
                    component={CourseDetail}
                    exact
                  />
                  <Route
                    path="/units/:seoFriendlyUrl"
                    component={UnitDetail}
                    exact
                  />
                  <StaticNamedRoute
                    routeName={null}
                    path="/"
                    component={Home}
                    exact
                  />
                  <Route render={() => <Redirect to="/" />} />
                </Switch>
              </Router>
              <Help />
            </MobileApplyLinkProvider>
          </PageNameProvider>
        </StudyModeProvider>
      </AppInsightsErrorBoundary>
    </NotifierProvider>
  </AppInsightsContext.Provider>
);

export default App;
