import PropTypes from 'prop-types';

import './Accordion.scss';

const Accordion = ({ children, label, controlId }) => (
  <div className="accordion">
    <button
      className="accordion__heading accordion__toggle"
      aria-controls={controlId}
      aria-expanded="false"
      type="button"
    >
      {label}
      <span className="ico ico-chevron-fat ico--deep-blue">
        <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <path d="M10.91 30.41l-2.82-2.82L19.67 16 8.09 4.41l2.82-2.82L25.33 16 10.91 30.41z" />
        </svg>
      </span>
    </button>
    <div
      className="accordion__content content"
      id={controlId}
      aria-hidden="false"
    >
      {children}
    </div>
  </div>
);

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  controlId: PropTypes.string.isRequired,
};

export default Accordion;
