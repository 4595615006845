import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

import history from './history';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history },
    },
    excludeRequestFromAutoTrackingPatterns: [
      /.*www.google-analytics.com.*/g,
      /.*stats.g.doubleclick.net.*/g,
    ],
  },
});

if (process.env.REACT_APP_INSTRUMENTATION_KEY) {
  appInsights.loadAppInsights();
}

export { reactPlugin, appInsights };
