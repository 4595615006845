import { useEffect, useState, useContext } from 'react';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import { API } from 'aws-amplify';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

import { searchCourseDetails as courseDetailsQuery } from '../../../graphql/queries';
import Disclaimer from '../../Disclaimer';
import {
  Page,
  Primary,
  Header,
  Subheader,
  Accordion,
  AccordionGroup,
  ReadMoreParagraph,
  Card,
  CardContent,
  CardGroup,
  PreWrap,
  renderLinks,
} from '../../Common';

import EntryAndCompletionDetails from './EntryAndCompletionDetails';
import LearningOutcomes from './LearningOutcomes';
import SideBar from '../../SideBar';
import Summary from '../../SideBar/Summary';
import MobileCard from '../../SideBar/MobileCard';
import SideBarLinks from '../../SideBarLinks';
import SelectVersion from '../../SelectVersion';
import Location from '../../Location';
import CourseStructure from '../../CourseStructure';
import { StudyModeContext } from '../../StudyModeContext';
import { LoadingMessage, ErrorMessage } from '../../Message';
import useIsMobile from '../../Utils/isMobile';
import plural from '../../Utils/plural';
import { versionSortOrder, parseVersion } from '../../Utils/version';
import { some } from '../../Utils/tools';
import { PageNameContext } from '../../BreadCrumbs/PageNameProvider';
import { MobileApplyLinkContext } from '../../BreadCrumbs/MobileApplyLinkProvider';
import { NotifierContext } from '../../Notifier/NotifierProvider';
import hasError from '../../Utils/hasError';

import './CourseDetail.scss';
import './CourseDetailPrint.scss';

const initialState = {
  isLoading: true,
  isError: false,
  isRedirect: false,
  courseDetails: {},
  courseVersions: [],
  selectedCourseVersion: {},
};

const formatDuration = ({ years, months }) => {
  if (years === null || months === null) {
    return null;
  }

  if (years > 0 && months > 0) {
    return `${years} ${plural(years, 'year')} ${months} ${plural(
      months,
      'month',
    )}`;
  }

  if (years > 0) {
    return `${years} ${plural(years, 'year')} `;
  }

  if (months > 0) {
    return `${months} ${plural(months, 'month')}`;
  }

  return '';
};

const CourseDetail = () => {
  const isMobile = useIsMobile();
  const history = useHistory();
  const { seoFriendlyUrl } = useParams();
  const { shortcode } = useContext(StudyModeContext);
  const { setPageName } = useContext(PageNameContext);
  const { setMobileApplyLink } = useContext(MobileApplyLinkContext);
  const { setMessage } = useContext(NotifierContext);
  const appInsights = useAppInsightsContext();
  const international = shortcode === 'int' ? '&InternationalStudent=yes' : '';

  const [
    {
      isLoading,
      isError,
      isRedirect,
      courseDetails,
      courseVersions,
      selectedCourseVersion,
    },
    setDetailsState,
  ] = useState(initialState);

  const handleError = (error, trackException) => {
    const { errors } = error;
    if (hasError(errors, 'MIN_ENTRIES')) {
      setDetailsState({
        ...initialState,
        isRedirect: true,
      });
      return;
    }
    console.error(error);
    trackException(
      new Error(
        `Query failed with errors: ${errors
          .map(({ message }) => message)
          .join(',')}`,
      ),
    );
    setDetailsState({ ...initialState, isError: true });
  };

  useEffect(() => {
    const searchCourseDetailsById = async (
      seoFriendlyUrlSuffix,
      studyMode,
      signal,
    ) => {
      setDetailsState(initialState);
      setMessage('Loading course details');

      try {
        const {
          data: { searchCourseDetails },
        } = await API.graphql({
          query: courseDetailsQuery,
          variables: { seoFriendlyUrlSuffix, studyMode },
          authMode: 'AWS_IAM',
        });

        if (signal.aborted) {
          return;
        }

        setPageName(searchCourseDetails.courseDetails.longTitle);
        setMobileApplyLink(
          `https://www.curtin.edu.au/study/offering/${seoFriendlyUrlSuffix}/?apply`,
        );

        const versionsDetails = searchCourseDetails.courseVersions
          .map(parseVersion)
          .sort(
            (versionA, versionB) =>
              versionSortOrder.indexOf(versionA.tense) -
              versionSortOrder.indexOf(versionB.tense),
          );

        const selectedVersion = versionsDetails
          .filter(({ value }) => value === seoFriendlyUrl)
          .slice(-1)[0];

        setDetailsState({
          ...initialState,
          isLoading: false,
          courseDetails: searchCourseDetails.courseDetails,
          courseVersions: versionsDetails,
          selectedCourseVersion: selectedVersion,
        });
        setMessage(
          `Course details for ${searchCourseDetails.courseDetails.longTitle} has finished loading`,
        );
      } catch (error) {
        if (signal.aborted) {
          return;
        }
        setMessage('There was an error displaying information for this course');
        handleError(error, (exception) => {
          if (appInsights) {
            appInsights.trackException({ exception });
          }
        });
      }
    };

    const abortController = new AbortController();

    searchCourseDetailsById(seoFriendlyUrl, shortcode, abortController.signal);
    return () => abortController.abort();
  }, [
    seoFriendlyUrl,
    shortcode,
    international,
    setPageName,
    setMobileApplyLink,
    setMessage,
    appInsights,
  ]);

  if (isRedirect) {
    return <Redirect to="/" />;
  }

  if (isError) {
    return <ErrorMessage />;
  }

  if (isLoading) {
    // TODO: replace with something like
    // https://github.com/danilowoz/react-content-loader
    return <LoadingMessage />;
  }

  const campusLocations = courseDetails.locationsAndDelivery.map(
    ({ campus }) => campus,
  );

  const formattedDuration = formatDuration(courseDetails.duration);

  const required = courseDetails.category !== '625'; // 625 = study set

  const isHiddenLocation = courseDetails.hiddenLocation;

  const summaryOptions = [
    ...(!isHiddenLocation ? [['Location', campusLocations, true]] : []),
    ['Duration', [formattedDuration], required],
    ['Award code', [courseDetails.awardCode], required],
    ['CRICOS Code', [courseDetails.cricosCode], false],
  ];

  const sideBarOptions = {
    title: courseDetails.longTitle,
    studyArea: courseDetails.studyArea,
    seoFriendlyUrl,
    udc: courseDetails.udc,
  };

  return (
    <PreWrap>
      <Page>
        <Primary>
          <Header title={courseDetails.longTitle} />
          <Subheader
            primary={courseDetails.udc}
            secondary={selectedCourseVersion}
            disclaimer="This version is currently on the planned status and subject to change,
            students are suggested to check the course again in the future."
          />
          {courseVersions.length > 1 && (
            <SelectVersion
              options={courseVersions}
              value={{
                ...selectedCourseVersion,
                label: 'Other versions of this course',
              }}
              onChange={(value) => {
                history.push(`/courses/${value.value}`);
              }}
            />
          )}
          <MobileCard summaryOptions={summaryOptions} />
          <CardGroup>
            <Card>
              <CardContent>
                <Summary summaryOptions={summaryOptions} />
              </CardContent>
            </Card>
          </CardGroup>
          <h2>Course overview</h2>
          {courseDetails.courseOverviewGeneric && (
            <p>{courseDetails.courseOverviewGeneric}</p>
          )}
          <ReadMoreParagraph>
            <p>
              {courseDetails.courseOverviewSpecific && (
                <span className="paragraph">
                  {renderLinks(courseDetails.courseOverviewSpecific)}
                </span>
              )}
              {courseDetails.overviewNotes && (
                <>
                  <strong className="h3">Course overview note</strong>
                  <span className="paragraph">
                    {renderLinks(courseDetails.overviewNotes)}
                  </span>
                </>
              )}
            </p>
          </ReadMoreParagraph>
          <AccordionGroup>
            {some(
              courseDetails.professionalRecognition,
              courseDetails.accreditation,
            ) && (
              <Accordion
                label="Professional recognition"
                controlId="professional-recognition"
              >
                {courseDetails.professionalRecognition && (
                  <p> {renderLinks(courseDetails.professionalRecognition)}</p>
                )}
                {courseDetails.accreditation && (
                  <>
                    <h2 className="h3">Accreditation</h2>
                    <p>{courseDetails.accreditation}</p>
                  </>
                )}
              </Accordion>
            )}
            {courseDetails.careerOpportunities && (
              <Accordion
                label="Career opportunities"
                controlId="career-opportunities"
              >
                <p>{courseDetails.careerOpportunities}</p>
              </Accordion>
            )}
            <EntryAndCompletionDetails
              entryRequirements={courseDetails.entryRequirements}
              entryRequirementsSpecific={
                courseDetails.entryRequirementsSpecific
              }
              entryOrCompletionNotes={courseDetails.entryOrCompletionNotes}
              creditForRecognisedLearning={
                courseDetails.creditForRecognisedLearning
              }
              creditForRecognisedLearningNotes={
                courseDetails.creditForRecognisedLearningNotes
              }
              intermediateAwards={courseDetails.intermediateAwards}
              awardPathwayGeneric={courseDetails.awardPathwayGeneric}
              awardList={courseDetails.awardList}
              completionRequirements={courseDetails.completionRequirements}
              durationAndAvailability={courseDetails.durationAndAvailability}
            />
            <LearningOutcomes
              organisationGeneric={courseDetails.organisationGeneric}
              organisation={courseDetails.organisation}
              learningOutcomes={courseDetails.learningOutcomes}
            />
            {courseDetails.additionalExpenses && (
              <Accordion
                label="Additional course expenses"
                controlId="additional-expenses"
              >
                <p>{courseDetails.additionalExpenses}</p>
              </Accordion>
            )}
          </AccordionGroup>
          {courseDetails.courseStructure.length > 0 && (
            <>
              <CourseStructure structure={courseDetails.courseStructure} />
              {courseDetails.structureNotes && (
                <p>{courseDetails.structureNotes}</p>
              )}
              <p>
                Curtin University reserves the right to alter the internal
                composition of any course to ensure learning outcomes retain
                maximum relevance. Any changes to the internal composition of a
                course will protect the right of students to complete the course
                within a normal timeframe and will not result in additional cost
                to students through a requirement to undertake additional units.
              </p>
            </>
          )}
          {!isHiddenLocation && (
            <>
              <h2 className="loc-delivery-header">Location and delivery</h2>
              {courseDetails.locationsAndDelivery.length > 0 && (
                <>
                  <p>
                    For start dates, please view the&nbsp;
                    <a
                      href="https://students.curtin.edu.au/essentials/academic-calendar/"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="academic calendar"
                    >
                      academic calendar
                    </a>
                    .
                  </p>
                  <Location options={courseDetails.locationsAndDelivery} />
                  <p>
                    The information displayed above refers to study periods and
                    locations where the course is available for first time
                    entry. Students are normally only offered or admitted to a
                    course once
                  </p>
                </>
              )}
            </>
          )}
        </Primary>
        <SideBar
          sideBarOptions={sideBarOptions}
          summaryOptions={summaryOptions}
          type="course"
        />
      </Page>
      <Disclaimer />
      {isMobile && <SideBarLinks isMobile={isMobile} />}
    </PreWrap>
  );
};

export default CourseDetail;
