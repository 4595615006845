import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import recursiveClone from '../Utils/recursiveClone';

import './ReadMoreParagraph.scss';

const keyPressHandler = (event, actionCallback) => {
  if (event.code === 'Enter' || event.code === 'Space') {
    event.preventDefault();
    actionCallback();
  }
};

const checkOverflow = (refParagraph) => {
  if (refParagraph === undefined || refParagraph === null) return false;

  return (
    refParagraph.clientWidth < refParagraph.scrollWidth ||
    refParagraph.clientHeight < refParagraph.scrollHeight
  );
};

const ReadMoreParagraph = ({ children, showMore }) => {
  const [isExpanded, setExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const refParagraph = useRef(undefined);

  useEffect(() => {
    setIsOverflowing(checkOverflow(refParagraph.current));
  }, [children]);

  if (!children) {
    return null;
  }

  return (
    <>
      <div
        ref={refParagraph}
        className={`handbook__read_more_paragraph${
          isExpanded ? '' : ' truncate'
        }${showMore ? ' show_more' : ''}`}
      >
        {recursiveClone(children, [
          [
            (c) => c && c.type === 'a',
            {
              tabIndex: isExpanded ? '0' : '-1',
            },
          ],
        ])}
      </div>
      {isOverflowing && (
        <center className="btn-container">
          <div
            role="button"
            className="button"
            tabIndex="0"
            aria-pressed="false"
            onClick={() => setExpanded(!isExpanded)}
            onKeyPress={(e) =>
              keyPressHandler(e, () => setExpanded(!isExpanded))
            }
          >
            {isExpanded ? 'Read less' : 'Read more'}
          </div>
        </center>
      )}
    </>
  );
};

ReadMoreParagraph.propTypes = {
  children: PropTypes.node,
  showMore: PropTypes.bool,
};

ReadMoreParagraph.defaultProps = {
  children: null,
  showMore: false,
};

export default ReadMoreParagraph;
