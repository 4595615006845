import PropTypes from 'prop-types';

import './Subheader.scss';

const Subheader = ({
  primary,
  secondary: { dateLabel, versionLabel, tense },
  disclaimer,
}) => (
  <p className="handbook__subheader">
    {primary}
    {dateLabel && <small>{dateLabel}</small>}
    <br aria-hidden />
    {versionLabel && <span className="smaller">{versionLabel}</span>}
    <br aria-hidden />
    {tense === 'Future' && <span className="smaller">{disclaimer}</span>}
  </p>
);

Subheader.propTypes = {
  primary: PropTypes.string.isRequired,
  secondary: PropTypes.shape({
    dateLabel: PropTypes.string,
    versionLabel: PropTypes.string,
    tense: PropTypes.string,
  }),
  disclaimer: PropTypes.string.isRequired,
};

Subheader.defaultProps = {
  secondary: {
    dateLabel: null,
    versionLabel: null,
  },
};

export default Subheader;
